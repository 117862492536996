import styled from '@emotion/styled';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import Handler from '../../Handler';
import { ModalPdfView } from '../../components';
import { LangContext } from '../../contexts';
import { useLabel } from '../../hooks';

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 13rem 1fr 120px 100px 100px;
  padding: 10px;
`;

const TableSubRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px 100px 100px;
`;

export const DetailDocumentOld = (props) => {
  const { docData, type } = props;
  const ref = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [document, setDocument] = useState(null);
  const { isKorean } = useContext(LangContext);
  const { TABLE_HEADER, DOCUMENT_METHODOLOGY, DOCUMENT_PROJECT, DOCUMENT_STANDARD, ERROR } = useLabel();

  const handleDocumentModal = useCallback((doc) => {
    setDocument(doc);
    setShowModal(true);
  }, []);

  const documentType = useMemo(() => {
    if (type === 'methodology') return DOCUMENT_METHODOLOGY;
    if (type === 'project') return DOCUMENT_PROJECT;
    if (type === 'standard') return DOCUMENT_STANDARD;
    return DOCUMENT_STANDARD;
  }, [type, DOCUMENT_METHODOLOGY, DOCUMENT_PROJECT, DOCUMENT_STANDARD]);

  const docTypeKeys = useMemo(() => {
    if (type === 'methodology') return ['BASIC', 'RESULT', 'CONSULTATION'];
    if (type === 'project') return ['BASIC', 'MONITORING','VALID', 'VERIFY', 'RESULT', 'CONSULTATION'];
    if (type === 'standard') return ['HISTORY', 'CONSULTATION'];
    return ['HISTORY', 'CONSULTATION'];
  }, [type]);

  const noDataRow = (type, idx) => (
    <TableSubRow key={`doc-no-data-sub-row-${idx}`} className={`w-full items-center`}>
      <div className='text-gray-400 leading-6 text-center'>{ERROR['NO_DOCUMENTS']}</div>
      <div className='flex justify-center text-gray-400'>-</div>
      <div className='flex justify-center text-gray-400'>-</div>
      <div className='flex justify-center text-gray-400'>-</div>
    </TableSubRow>
  );

  return (
    <section>
      <section className='flex border-y border-solid border-gray-200 bg-slate-100'>
        <TableRow className='w-full font-bold text-gray-500 border-t border-solid border-gray-400'>
          <div className='flex justify-center'>{TABLE_HEADER['TYPE']}</div>
          <div className='flex justify-center'>{TABLE_HEADER['DOC_TITLE']}</div>
          <div className='flex' style={{display: 'flex', alignItems:'right', width: '130px'}}>{TABLE_HEADER['CREATED_DATE']}</div>
          <div className='flex justify-center'>{TABLE_HEADER['PREVIEW']}</div>
          <div className='flex justify-center'>{TABLE_HEADER['DOWNLOAD']}</div>
        </TableRow>
      </section>
      {docTypeKeys.map((el, idx) => {
        return (
          <section key={`doc-data-item-${idx}`} className='w-full flex border-b border-solid border-gray-200' >
            <div className='flex justify-center items-center px-7 text-center leading-6' style={{width: isKorean ? '136px': "250px"}} >{documentType[el]}</div>
            <div className='w-full box-content flex flex-col gap-3 justify-center items-center px-2 py-4'>
              {docData[el]?.length > 0
                ? docData[el].map((doc, idx) => {
                    const file = isKorean ? doc.attachedFile : doc.attachedFileEn;
                    const title = isKorean ? doc.title : doc.titleEn;
                    const date = Handler.geni18nDate(isKorean, doc.createdAt * 1000);
                    return (
                      <TableSubRow key={`doc-data-sub-row-${idx}`} className={`w-full items-center`} >
                        <div className='flex justify-center text-center leading-6' >{title}</div>
                        <div className='flex justify-center' style={{whiteSpace: 'pre-wrap'}}>{date}</div>
                        <div className='flex justify-center'>
                          <SearchIcon
                            color='primary'
                            className='cursor-pointer'
                            onClick={() => handleDocumentModal(file)}
                          />
                        </div>
                        <div className='flex justify-center'>
                          <a href={file} download={file} target='_blank' rel='noreferrer'>
                            <FileDownloadOutlinedIcon color='primary' />
                          </a>
                        </div>
                      </TableSubRow>
                    );
                  })
                : noDataRow(el, 0)}
            </div>
          </section>
        );
      })}
      <Modal ref={ref} open={showModal} onClose={() => setShowModal(false)}>
        <Box>
          <ModalPdfView url={document} onClose={() => setShowModal(false)} />
        </Box>
      </Modal>
    </section>
  );
};
