import React from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';

export const BasicTab = (props) => {
  const { index, onChange, meta } = props;
  const { t } = useTranslation();

  return (
    <article
      className={Handler.BuildArticleClassname('basic-tab', meta)}
      style={meta && meta.style ? { ...meta.style, whiteSpace: 'pre-wrap'} : {whiteSpace: "pre-wrap"}}
    >
      <ul>
        {meta &&
          meta.menus.map((item, menu_index) => {
            return (
              <li
                key={`menu-${menu_index}`}
                onClick={() => {
                  onChange(menu_index);
                }}
                className={index === menu_index ? 'active' : ''}
              >
                <span>{t(item)}</span>
              </li>
            );
          })}
      </ul>
    </article>
  );
};
