import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import React from 'react';
import { NoDataRow } from './NoDataRow';
import { useLabel } from '../../hooks';
import {useContext } from 'react';
import { LangContext } from '../../contexts';



export const TableRecruitment = (props) => {
const { data, rowClick } = props;
const { isKorean } = useContext(LangContext);
const {RECRUITMENT} = useLabel();


  return (
    <article className='table-recruit'>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow align='left' style={{ width: 240, paddingLeft: '20px'}}>
              <TableCell  style={{ width: 240, paddingLeft: '20px'}}>
                <p className='py-1'>{RECRUITMENT['TITLE']}</p>
              </TableCell>
              <TableCell align='left' style={{ width: 240, cursor: 'pointer', }}>
                <p className='py-1' style={{ cursor: 'pointer', paddingLeft: '20px'}}>{RECRUITMENT['ROLE']}</p>
              </TableCell>
              <TableCell align='left' style={{width: 240, cursor: 'pointer', paddingLeft: '20px'}}>
                <p className='py-1'>{RECRUITMENT['DEPARTMENT']}</p>
              </TableCell>
              <TableCell align='left' style={{ width: 240, paddingLeft: '20px'}}>
                <p className='py-1'>{RECRUITMENT['TYPE']}</p>
              </TableCell>
              <TableCell align='left' style={{ width: 240,  paddingLeft: '20px' }}>
                <p className='py-1'>{RECRUITMENT['UPLOAD-DATE']}</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length > 0 ? (
              data.map((row, seq, id) => (
                <TableRow
                  key={`table-recruitment-${row.seq}`}
                  onClick={() => rowClick(row.seq)}
                  style={{ cursor: 'pointer'}}

                >
                  <TableCell align='left' style={{fontWeight: 300, color:'#535862'}}>{row.title}</TableCell>
                  <TableCell align='left' style={{fontWeight: 300, color:'#535862'}}>{row.job}</TableCell>
                  <TableCell align='left' style={{fontWeight: 300, color:'#535862'}}>{row.dept}</TableCell>
                  <TableCell align='left' style={{fontWeight: 300, color:'#535862'}}>
                    {row.type === 'FULLTIME'
                      ? '정규직'
                      : row.type === 'CONTRACT'
                      ? '계약직'
                      : '인턴'}
                  </TableCell>
                  <TableCell align='left' style={{fontWeight: 300, color:'#535862'}}>
                    {dayjs(row.uploadDate).format('YYYY.MM.DD')}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <NoDataRow content={ isKorean ? '현재 진행중인 채용공고가 없습니다.': 'No positions currently available.'} colSpan={5} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </article>
  );
};
