import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import API from '../../api';
import { Breadcrumbs, Button, CheckBox, ModalPrivacyTerm, TextArea, TextInput } from '../../components';
import { LangContext, SnackbarContext, UserContext } from '../../contexts';
import { useForm, useLabel } from '../../hooks';
import { SelectBox} from '../../components';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  bgcolor: 'background.paper',
  border: '1px solid #bcbcbc',
  p: 0,
  boxSizing: 'border-box',
};
export const Grievance = (props) => {
  const { t } = useTranslation();
  const { MSG } = useLabel();
  const { userInfo } = useContext(UserContext);
  const { isKorean } = useContext(LangContext);
  const { kakao } = window;
  const { updateSnackbar } = useContext(SnackbarContext);
  const [formData, formMeta, formChange, setFormData, reset] = useForm(
    {
      name: '',
      email: '',
      phone: '',
      message: '',
      type: Grievance,
    },
    {
      name: {
        key: 'name',
        label: t('NAME'),
        className: 'point',
        required: true,
      },
      email: {
        key: 'email',
        label: t('EMAIL'),
        style: {},
        className: 'point',
        required: true,
      },
      phone: {
        key: 'phone',
        label: t('MOBILE'),
        className: 'point',
        required: true,
      },
      message: {
        key: 'message',
        label: t('CONTENT'),
        maxSize: 500,
        className: 'point',
        required: true,
      },
      type: {
        key: 'GRIEVANCE',
        label: t('GRIEVANCE'),
        maxSize: 500,
        className: 'point',
        required: true,
        menus: [
            {
                value: "PROJECT_METHODOLOGY",
                label: t("PROJECT_METHODOLOGY")
            },
            {
                value: "DECISION_MAKING_DISPUTES",
               label: t("DECISION_MAKING_DISPUTES")
            },
            {
                value: "STAKEHOLDER_DISPUTES",
                label: t("STAKEHOLDER_DISPUTES")
            },
            {
                value: "OPERATIONAL_CONCERNS",
                label: t("OPERATIONAL_CONCERNS")
            }
        ]
      },
    
      // files: {
      //   key: 'files',
      //   type: 'file',
      //   label: t('FILE'),
      //   path: 'dummy',
      //   max: 3,
      //   style: {
      //     gridColumn: '1/3',
      //   },
      //   required: false,
      // },
    },
    
    
  );
  const [aggree, setAggree] = useState(false);
  const [open, setOpen] = useState(false);
  
  const buildMeta = (meta) => {
    let meta_value = { ...meta };
  
      meta_value.readOnly = true;
    
    return meta_value;
  };
  useEffect(() => {
    if (userInfo) {
      setFormData({
        ...formData,
        email: userInfo.email,
        name: userInfo.name,
        phone: userInfo.phone,
        // fileUpload: userInfo.files
        type: userInfo.grievance,
        message: userInfo.message
      });
    }
  }, [userInfo]);



  const requsetSubmit = () => {
    console.log('requsetSubmit');

    if (!aggree) {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '개인정보 수집에 동의해주세요.',
      });
      return;
    }
    let body = Handler.formToBody(formData, formMeta);
    if (body) {
      API.CS.PostContact({ ...body }).then(
        (res) => {
          let result = res.data;
          console.log('CS PostContact result', result);
          updateSnackbar({
            type: 'success',
            open: true,
            message: '불만사항이 등록되었습니다.',
          });
        
        },
        (err) => {
          updateSnackbar({
            type: 'error',
            open: true,
            message: err.data.message,
          });
        },
      );
    } else {
      updateSnackbar({
        type: 'error',
        open: true,
        message: '필수정보를 확인해주세요.',
      });
    }
  };

  return (
    <article className='basic-page'>
      <article className='content__wrapper'>
        <section className='headding-text__container'>
          <header  style={{
            display: 'flex',
            justifyContent: 'center', 
        }}>
            <h1>{t("GRIEVANCE")}</h1>
          </header>
          <div className='right__box'>
            <Breadcrumbs type={'small'}></Breadcrumbs>
          </div>
        </section>
        <section className='grievance__container'>
          <ul className='form__box'>
            
            <TextInput value={formData.name} onChange={(value) => formChange(value, 'name')} meta={{label: t("NAME"), required: true}}></TextInput>
            <TextInput value={formData.email} onChange={(value) => formChange(value, 'email')} meta={{label: t("EMAIL"), required: true}}></TextInput>
            <TextInput value={formData.phone} onChange={(value) => formChange(value, 'phone')} meta={{label: t("MOBILE"), required: true}}></TextInput>
            <TextArea value={formData.message} onChange={(value) => formChange(value, 'message')} meta={{ label: t("CONTENT"), required: true}}
                  ></TextArea>
            <SelectBox
                    value={formData.type}
                    onChange={(value) => formChange(value, 'type')}
                    meta={{label: t("GRIEVANCE"), required: true,   menus: [
                      {
                          value: "PROJECT_METHODOLOGY",
                          label: t("PROJECT_METHODOLOGY")
                      },
                      {
                          value: "DECISION_MAKING_DISPUTES",
                         label: t("DECISION_MAKING_DISPUTES")
                      },
                      {
                          value: "STAKEHOLDER_DISPUTES",
                          label: t("STAKEHOLDER_DISPUTES")
                      },
                      {
                          value: "OPERATIONAL_CONCERNS",
                          label: t("OPERATIONAL_CONCERNS")
                      }
                  ] }}
                    
                  ></SelectBox>
             {/* <FileMultipleUploader
              value={formData.files}
              onChange={formChange}
              meta={formMeta.files}
            ></FileMultipleUploader> */}
            <div className='aggree__box'>
              <CheckBox
                value={aggree}
                onChange={(value) => {
                  setAggree(value);
                }}
                meta={{ label: MSG['AGREE_PRIVACY_TERM'] }}
              ></CheckBox>
              <div className='option__box'>
                <span
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  {t('SHOW')}
                </span>
              </div>
            </div>
            <section className='button__box'>
              <Button style={{ width: '100%' }} onClick={requsetSubmit} label={t('SUBMIT')}></Button>
            </section>
          </ul>
        
        </section>
      </article>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box sx={style}>
          <article className='contact-privacy__modal'>
            <ModalPrivacyTerm
              onClose={() => {
                setOpen(false);
              }}
            />
          </article>
        </Box>
      </Modal>
    </article>
  );
};
