import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { ModalLoading, PageHeader, TablePaging } from '../../components';
import { TableRecruitment } from '../../components/table/Recruitment';
import { useLabel, usePagination } from '../../hooks';
import { LangContext } from '../../contexts';
import API from '../../api';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export const Recruitment = (props) => {
  const history = useHistory();
  const { RECRUITMENT, MSG } = useLabel();
  const [list, setList] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [page, totalPages, totalElements, onPageChange, setPage, setTotalPages] = usePagination(0);
  const { isKorean } = useContext(LangContext);

  const fetchRecruitmentList = async () => {
    try {
      const res = await API.CS.GetRecruitList();
      const { data, totalItems } = res.data;
      setList(res.data.content) 
      setPage(totalElements);
      setTotalPages(totalPages);

    } catch (err) {
      console.error('Error fetching recruitment list:', err);
    } finally {
      setSpinner(false); 
    }
  };

  useEffect(() => {
    fetchRecruitmentList();
  }, [page]); 

  

  return (
    <article className="basic-page">
      <article>
        <PageHeader type='standard' showBreadcrumbs={false} />  
        <header
        style={{
        display: 'flex',
        justifyContent: 'center', 
        alignItems: 'center', 
        }}>
       <h1 style={{fontWeight: 600, lineHeight: '48px', fontSize: '40px', color:'#222222'
       }}>{RECRUITMENT['RECRUITMENT']}</h1>
      </header>
        <span className="flex items-center justify-center mb-[95px] mt-8" style={{color: '#606369', fontWeight: 300, fontSize: '16px', padding: isKorean ? '10px': '10px', whiteSpace: 'pre-wrap', textAlign: 'center', lineHeight: '26px', marginBottom: isKorean  ? "90px" : "69px" }}>
          {isKorean ? "그리너리는 한국의 최초 자발적 탄소인증센터 'POPLE'를 운영하고 있습니다.\n과학적으로 검증된 온실가스 감축 및 제거 프로젝트를 적극적으로 지원하며, 기후변화와 탄소중립이라는 전 지구적 과제 해결에 기여하고자 합니다.\n무결하고 투명한 온실가스 감축으로 기후변화 대응에 함께 동참할 열정과 역량을 갖춘 인재를 모집합니다." : "Join us at POPLE Programme\nAt POPLE, we are dedicated to enhancing the integrity of carbon credits.\nAs Korea's leading voluntary carbon crediting program, we support scientifically robust GHG reduction and removal projects. \nWe are seeking passionate individuals to join our team and contribute to a global effort to combat climate change."}
        </span>
        <TableRecruitment data={list} rowClick={(row) => {
          history.push(`/cs/recruitment/${row}`)
        }} />
        <div className="mb-0 my-[200px]">
          <TablePaging page={page} totalPage={totalPages} onChange={onPageChange} />
        </div>
      </article>
    </article>
  );
};
