import React, { useCallback, useContext, useEffect, useState, useTransition } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Handler from '../../Handler';
import API from '../../api';
import Utils from '../../api/Utils';
import { PageHeader, SearchFilter, TablePaging } from '../../components';
import { TableProjectRegistry } from '../../components/table/ProjectRegistry';
import { LangContext, SnackbarContext } from '../../contexts';
import { useLabel, usePagination } from '../../hooks';
import { Button } from 'react-scroll';
import { useTranslation} from 'react-i18next';
import dayjs from 'dayjs';

export const ExcelDownloadButton = styled(Button)`
  border: 1px solid #1bc47d !important;
  span,
  svg {
    color: #1bc47d;
    font-weight: 700;
  }
`;


export const ProjectRegistryListPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const {onDownload} = props;
  const {t} = useTranslation();
  const { PROJECT_STATUS, TABLE, BUTTON } = useLabel();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { isKorean } = useContext(LangContext);
  const [list, setList] = useState(null);
  const [spinner, setSpinner] = useState(false)
  const [areaType, setAreaType] = useState('');
  const [subAreaType, setSubAreaType] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [projectLastDocActionStatus, setProjectLastDocActionStatus] = useState('');
  const [lastSearchBody, setLastSearchBody] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);

  const getRegistryList = (init_page, isReset = false) => {
    let params = null;
    if (init_page === 0) {
      params = {
        page: init_page ? init_page : page,
        size: Handler.VARIABLES.PAGE_LIMIT,
        searchValue: isReset ? '' : searchValue,
        areaType: isReset ? '' : areaType,
        subAreaType: isReset ? '' : subAreaType,
        projectLastDocActionStatus: isReset ? '' : projectLastDocActionStatus,
        lang: isKorean ? 'ko' : 'en',
      };
      setLastSearchBody({ ...params });
    } else {
      params = {
        ...lastSearchBody,
        page: init_page ? init_page : page,
        size: Handler.VARIABLES.PAGE_LIMIT,
      };
    }
    replaceSearchQuery(params);
    API.Registry.GetRegistryList('project', params).then(
      (res) => {
        const { content, totalPages } = res.data;
        setList(content);
        setTotalPage(totalPages);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  useEffect(() => {
    if (lastSearchBody) {
      getRegistryList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

const handleExcelDownload = useCallback(async () => {
  if (!list || list.length === 0) {
    return updateSnackbar({
      type: 'error',
      open: true,
      message: 'No data available for download.',
    });
  }

  setSpinner(true);
  try {
    const params = { searchValue, lang: isKorean ? 'ko' : 'en' };
    const res = await API.Registry.GetRegistryExcel(params);
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `[POPLE] ${
        isKorean ? '프로젝트 현황' : 'Project_List'
      }_${Handler.geni18nDate(isKorean, dayjs())}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();

    updateSnackbar({
      type: 'success',
      open: true,
      message: 'Excel downloaded successfully.',
    });
  } catch (err) {
    updateSnackbar({
      type: 'error',
      open: true,
      message: err.message || 'Failed to download Excel.',
    });
  } finally {
    setSpinner(false);
  }
}, [list, searchValue, isKorean, updateSnackbar]);



  useEffect(() => {
    if (location.search) {
      const params = {
        ...Utils.ParamsToObject(history.location.search),
      };
      setLastSearchBody({
        ...params,
      });
      setPage(Number(params.page));
    }
  }, [location]);

  useEffect(() => {
    requestSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (lastSearchBody) {
      getRegistryList();
    }
  }, [page]);
  const replaceSearchQuery = (params) => {
    history.replace({
      search: Utils.ObjectToParams({
        ...params,
        page: page,
        size: Handler.VARIABLES.PAGE_LIMIT,
      }),
    });
  };

  const handleSearchFilter = (params) => {
    if ('searchValue' in params) {
      setSearchValue(params.searchValue);
    }
    if ('areaType' in params) {
      setAreaType(params.areaType === 'all' ? '' : params.areaType);
    }
    if ('subAreaType' in params) {
      setSubAreaType(params.subAreaType === 'all' ? '' : params.subAreaType);
    }
    if ('projectLastDocActionStatus' in params) {
      setProjectLastDocActionStatus(params.projectLastDocActionStatus);
    }
  };

  const resetFilter = (e) => {
    setSearchValue('');
    setAreaType('');
    setSubAreaType('');
    setProjectLastDocActionStatus('');
    setPage(0);
    getRegistryList(0, true);
  };

  const requestSearch = () => {
    getRegistryList(0);
  };

  const tableRowClick = (item) => {
    history.push('/registry/project/detail/' + item.seq);
  };

    return (
    <article className='basic-page'>
      <PageHeader type='project-registry' title={`${TABLE['PROJECT_TITLE']}`} />
      <article className='content__wrapper'>
        <div className='w-full flex mt-3 justify-end border-t border-gray-200'>
         <ExcelDownloadButton
         style={{backgroundColor: '#FFFFFF', height: 44, alignContent: 'center', justifyContent: 'center', display: 'flex' }}
         variant='outlined'
         size='medium'
         color='#FFFFFF'
         
         className ='flex items-center py-2 gap-2 h-10 rounded-md border border-gray-500 p-4'
         onClick={handleExcelDownload}
         >
          <span className='text-md'> {BUTTON['LIST_DOWNLOAD']}</span>
          <FileDownloadOutlinedIcon color='grey.100' />
         </ExcelDownloadButton>
         </div>
        <SearchFilter
          placeholder='SEARCH_PROJECT'
          isProject={true}
          onChange={(params) => handleSearchFilter(params)}
          onSearch={requestSearch}
          onReset={resetFilter}
          onDownload={handleExcelDownload} 
        />
        
        <TableProjectRegistry data={list} rowClick={tableRowClick} />
     
        <div className='my-6'>
          <TablePaging page={page} totalPage={totalPage} onChange={onPageChange}></TablePaging>
        </div>
      </article>
    </article>
  );
};


