import styled from '@emotion/styled';
import { color, media } from '../../styles/style';

export const MainHeader = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: inherit;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
  &.is__transparent {
    background: rgba(255, 255, 255, 0);
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0);
    .pc-nav__wrapper,

    .pc-nav__main {
      
      .left__side,
      .right__side,
      .sub-nav__list {
     
        span {
          color: #fff;
        }
      }
    }
  }
  article.content__wrapper {
    min-width: ${media.$wrapper_min_width};
    max-width: ${media.$wrapper_max_width};
  }
  .left__side{
  
  }
  .right__side {
    display: flex;
    align-items: center;

  }
  .left__side {
    height: 100%;
    width: 100%;
  
    .nav__box {
      height: inherit;
      width: inherit;
      display: flex;

    }
    .nav__box ul {
      height: inherit;
      width: inherit;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      & > li {
        display: flex;
        /* position: absolute; */
        height: 100%;
        border-bottom: solid 4px transparent;
        box-sizing: border-box;
        &:last-child {
          margin-right: 0px;
        }
        &.on {
          border-color: $--main-500;
          & > span {
            color: $--main-500;
          }
        }
        &.active {
          & > span {
            color: $--main-500;
          }
        }
        & > span {
          cursor: pointer;
          font-size: 18px;
          font-weight: 800;
          color: $--gray-600;
        }
      }
    }
  }
  .right__side {
    padding: 10px 0;
    justify-content: flex-end;
    /* width: 200px; */
 
    ul {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      &.loggedin {
        grid-template-columns: repeat(2, 1fr);
        li {
          &:nth-of-type(2) {
            width: 115px;
          }
        }
      }
      li {
        width: 115px;
        text-align: right;
        cursor: pointer;
        /* margin-right: 20px; */
        &:nth-of-type(2) {
          width: 130px;
        }
        &:last-of-type {
          margin: 0;
        }
        span.divider {
          margin: 0 5px;
        }
        span.registry {
          font-weight: 700;
        }
      }
    }
  
  }
  .sub-nav__list {
    ul {
      li {
        padding-bottom: 18px;
        cursor: pointer;
        span {
          position: relative;
          display: inline-flex;
          transition: all 2s ease-out 100ms;
          &:hover {
            &::before {
              content: '';
              position: absolute;
              display: inline-flex;
              width: 100%;
              height: 2px;
              bottom: -5px;
              background: ${color.$main500};
            }
          }
        }
      }
    }
    &.sub-right__side {
      ul {
        width: 115px;
        li {
          text-align: right;
        }
      }
    }
  }
  article.pc-nav__wrapper {
    width: 100%;
    display: grid;
    white-space: pre-wrap;
    grid-template-columns: 1fr 100px;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    &.loggedin {
      grid-template-columns: 1fr 100px;
      gap: 20px;
    }
  }
`;

export const PcNavigation = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 16px;
  /* height: 104px; */
  /* box-shadow: 0 0 12px 0 rgb(0 0 0 / 10%); */
  z-index: 11;
`;

export const MobileNavigation = styled.section`
  display: flex;
  justify-content: end;
  align-items: center;
  background: #fff;
  width: 100%;
  height: 60px;
  padding: 0px 20px;
  &.shadow {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
  }
  .menu__box,
  .menu-list__box {
    display: none;
  }
  @media only screen and (max-width: 1136px) {
    .menu__box {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      div {
        cursor: pointer;
      }
      .login {
        color: ${color.$main500};
      }
    }
    .menu-list__box {
      &.open {
        display: flex;
        z-index: 1000;
      }
      background-color: #5d3be5;
      display: none;
      position: fixed;
      right: 0px;
      top: 0px;
      /* max-width: 300px; */
      width: 100%;
      height: 100vh;
      flex-direction: column;
      overflow: auto;
      & > header {
        display: flex;
        align-items: center;
        padding: 24px 24px 0 24px;
        box-sizing: border-box;
        justify-content: space-between;
        margin-top: 16px;
        z-index: inherit;
        .logo__box {
          .logo-white {
            width: 95px;
            height: 30px;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
      .mobile-list__box {
        margin-top: 34px;
        display: flex;
        flex-direction: column;
        /* padding: 0px 16px; */
        z-index: inherit;

        &.user {
          height: auto;
          flex: 1;
          margin-bottom: 74px;
        }
        .mobile-list__item {
          display: flex;
          align-items: center;
          min-height: 52px;
          border-bottom: solid 1px #d8d8d8;
          z-index: inherit;

          h3 {
            font-weight: normal;
          }
        }
        .mobile-accordion__item {
          display: flex;
          align-items: center;
          min-height: 52px;
          width: 100%;
          z-index: inherit;

          &.last {
            border-bottom: none;
          }

          h3 {
            font-weight: bold;
            color: #fff;
          }

          .mobile-child__item {
            height: 48px;
            display: flex;
            align-items: center;

            span {
              font-size: 16px;
            }
          }
          .MuiAccordion-root {
            box-shadow: none;
            background-color: ${color.$main500};
            width: 100%;
          }
          .MuiCollapse-root {
            background-color: #fff;
          }
          .MuiAccordionSummary-root.Mui-expanded {
            height: 48px;
            min-height: 48px;
          }
          .MuiAccordionSummary-root {
            padding: 0px 24px;
            background-color: #5d3be5;
          }
          .MuiAccordionSummary-content,
          .MuiAccordionSummary-content.Mui-expanded {
            margin: 14px 0;
          }
          .MuiAccordionDetails-root {
            padding: 12px 24px;
            span {
              color: #5d3be5;
            }
          }
        }
      }
      .login__box {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        z-index: inherit;
        margin: 24px;
        padding: 24px 0;
        border-top: 2px solid #fff;
        div {
          padding-bottom: 16px;
          color: #fff;
        }
      }
      .logout__box {
        display: flex;
        height: 74px;
        background: #eeeeee;
        align-items: center;
        padding: 0px 16px;
        position: fixed;
        bottom: 0px;
        width: 100%;
        h3 {
          font-weight: normal;
        }
      }
    }
  }
`;

export const PcSubNavigation = styled.section`
  width: 100%;
  transition: all 0.3s linear;
  opacity: 0;
  &.open {
    opacity: 1;
  }
  .logo__fake {
    width: 164px;
  }
  .sub-right__side {
    display: flex;
    justify-content: flex-end;
    &.loggedin {
      ul,
      ul:last-of-type {
        padding: 0 0 24px 0;
      }
    }
    ul {
      padding: 0 0 24px 0;
      &:last-of-type {
        padding-right: 15px;
      }
    }
  }
  .sub-nav__box {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    ul {
      /* width: 146px; */
      width: 166px;
      padding-bottom: 24px;
      li {
        font-size: 16px;
        text-align: left;
        line-height: 1.5;
      }
    }
  }
`;
