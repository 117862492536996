import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTE_PATH } from '../../constants/routes';
import { LangContext } from '../../contexts';

export const PageHeader = ({ showBreadcrumbs = true, ...props }) => {
  const { title, detail, parentText = '', parentPath = '', description, children } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const NAVIGATION = t('ROUTE_PATH', { returnObjects: true });
  const match = history.location.pathname.split('/')[1];
  const { isKorean } = useContext(LangContext);
  const [isBoardPage, setIsBoardPage] = useState(false);

  const currentPage = ROUTE_PATH.find((el) => match === el.activePath);
  const language = 'ko';

  const findSubmenu = useCallback(
    (child) => {
      const current = child.find((el) => el.path === history.location.pathname);
      return current ? current.text : '';
    },
    [history],
  );

  const detailLabel = useMemo(() => {
    return currentPage?.child && NAVIGATION[findSubmenu(currentPage?.child)];
  }, [currentPage, NAVIGATION, findSubmenu]);

  const breadcrumbs = useMemo(() => {
    const path = history.location.pathname;
    const arr = [
      <p underline='hover' key='1' color='inherit'>
        {NAVIGATION[currentPage?.text]}
      </p>,
      <p key='2'>{detail || detailLabel}</p>,
    ];

    if (path === '/program/program/detail') {
      arr.pop();
      arr.push(<p key='2'>{t('programDetails')}</p>);
      return arr;
    }

    if (path.includes('detail') || detail) {
      arr.splice(
        1,
        0,
        <Link
          className='cursor-pointer '
          underline='hover'
          key='2'
          color='inherit'
          onClick={() => history.push(parentPath)}
        >
          {parentText}
        </Link>,
      );
      return arr;
    }
    return arr;
  }, [history, detail, detailLabel, parentPath, parentText, NAVIGATION, currentPage]);

  return (
    <article className='w-full max-w-screen-xl items-start py-8 mt-4'>
      <Stack spacing={2}>
        {showBreadcrumbs && (
          <Breadcrumbs color='#606369' separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
            {breadcrumbs}
          </Breadcrumbs>
        )}
      </Stack>
      <div className='mt-4'>
        <h1 className='whitespace-pre-wrap'>{title}</h1>
        {description && <p className='mt-12 whitespace-pre-wrap text-gray-700 leading-6'>{description}</p>}
        {children}
      </div>
    </article>
  );
};
