import Table from '@mui/material/Table';
import InfoIcon from '@mui/icons-material/Info';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import { SnackbarContext } from '../../contexts';
import { NoDataRow } from './NoDataRow';
import { LangContext } from '../../contexts';
import dayjs from 'dayjs';
import { PROJECT_STATUS } from '../../constants/menus';
import { useLabel } from '../../hooks';

export const TableProject = (props) => {
  const { data, rowClick, callback } = props;
  const { isKorean } = useContext(LangContext);
  const { t } = useTranslation();
  const { updateSnackbar } = useContext(SnackbarContext);
  const { category } = props;

  const { AREA_TYPE, TOOLTIPS, reductionExpect, startDate, endDate, PROJECT_STATUS, TABLE, ERROR, COUNTRY, ELIGIBLE_PERIOD } = useLabel();
  const renderHistoryButton = (item) => {
    const { status, degree } = item;
    switch (status) {
      case 'CanceledProject':
      case 'WritingProject': {
        return '-';
      }
      case 'WaitingForAuditProject': {
        if (degree === 1) {
          return '-';
        } else {
          return (
            <button
              onClick={(e) => {
                e.stopPropagation();
                callback('History', item);
              }}
            >
              {t('VIEW_HISTORY')}
            </button>
          );
        }
      }
      default: {
        return (
          <button
            onClick={(e) => {
              e.stopPropagation();
              callback('History', item);
            }}
          >
            {t('VIEW_HISTORY')}
          </button>
        );
      }
    }
  };

  const renderStatus = (item) => {
    const { status, degree } = item.projectDocAction;
    let status_text = t(Handler.ENUM.ACTION.STATUS(status));
    let text_active = false;
    let isView = false;

    switch (status) {
      case 'WritingProject': {
        text_active = true;
        break;
      }

      case 'AuditLaunch':
      case 'AuditProject':
      case 'WaitingForAuditLaunch':
      case 'WaitingForAuditProject': {
        status_text += `(${degree})`;
        break;
      }

      case 'RequestImproveLaunch':
      case 'RequestImproveProject': {
        text_active = true;
        isView = true;
        break;
      }
      case 'LaunchRejected':
      case 'CancelApproved':
      case 'ProjectRejected':
      case 'LaunchApproved':
      case 'ProjectApproved': {
        isView = true;
        break;
      }
      default: {
      }
    }
    return (
      <div className='status__box'>
        <span className={text_active ? 'active' : ''} style={{ marginBottom: 6 }}>
          {status_text}
        </span>
        {isView && (
          <em
            style={{ marginBottom: 6 }}
            onClick={(e) => {
              e.stopPropagation();
              callback('View', item);
            }}
          >
            {t('SHOW')}
          </em>
        )}
        <span className='date'>{Handler.getYYYYMMDDByUnix(item.createdAt)}</span>
      </div>
    );
  };

  const renderJob = (item) => {
    const { nextActionTypeList } = item;

    const isMain = (next_action) => {
      if (
        next_action === 'ImproveProjectComplete' ||
        next_action === 'ImproveLaunchComplete' ||
        next_action === 'ImproveProjectStart'
      ) {
        return 'main';
      } else {
        return null;
      }
    };

  // const renderEligiblePeriod = (el) => {
  //   if (el.label === TABLE['ELIGIBLE_PERIOD'] || el.label === TABLE['START_END_DATE']) {
  //     const start = el?.value?.startDate;
  //     /* 방법론일 경우 종료일을 유효기간(년)으로 계산해서 도출 */
  //     const end =
  //       category === 'methodology'
  //         ? dayjs(el?.value?.startDate).add(el?.value?.expiredYear, 'y')
  //         : el?.value?.endDate;
  //     const period = Handler.geni18nDatePeriod(isKorean, start, end);

  //     /* 프로젝트일 경우 유효기간(년)을 종료일로 계산해서 도출 */
  //     const year =
  //       category === 'methodology'
  //         ? `${TABLE['ELIGIBLE_PERIOD']} (${el.value?.expiredYear}${t('YEAR')})`
  //         : `(${dayjs(el?.value?.endDate).diff(el?.value?.startDate, 'y') + 1}${t('YEAR')})`;
  //     return (
  //       <div className='flex'>
  //         {el?.value?.startDate && dayjs(el?.value?.startDate).isValid() && <span>{period}</span>}
  //         <span className='ml-3 text-sm text-gray-400'>{year}</span>
  //       </div>
  //     );
  //   }
  // }

    return (
      <div className='button__box'>
        {nextActionTypeList &&
          nextActionTypeList.map((target_action, index) => {
            return (
              <button
                key={`last-doc-${index}`}
                className={isMain(target_action)}
                onClick={(e) => {
                  e.stopPropagation();
                  callback(target_action, item);
                }}
              >
                {t(Handler.ENUM.ACTION.NEXT(target_action))}
              </button>
            );
          })}
        {nextActionTypeList && nextActionTypeList.length === 0 && <span>-</span>}
      </div>
    );
  };

  return (
    <article className='table-project'>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow style={{fontSize: "10px"}}>
              <TableCell align='center' style={{ minWidth: 40, maxWidth: 40 }}>
                No
              </TableCell>
              <TableCell align='center' style={{ minWidth: 40, maxWidth: 40, lineHeight: 1.2 }}>
                ID
              </TableCell>
              <TableCell align='center' style={{ minWidth: 190, maxWidth: 240 }}>
                {t('PROJECT')}
              </TableCell>
     
              <TableCell align='center' style={{ minWidth: 93, maxWidth: 93, lineHeight: 1.2 }}>
                {t('PROJECT_TYPE')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 95, maxWidth: 95, lineHeight: 1.2 }}>
                {t('VALID_START_DATE')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 95, maxWidth: 95 }}>
                {t('VALID_END_DATE')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 90, maxWidth: 90 }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <p className='py-1'>{TABLE['ELIGIBLE_PERIOD']}</p>
                </div>
              </TableCell>
              <TableCell align='center' style={{ minWidth: 95, maxWidth: 95, width: 100 }}>
              <p className='py-1'>{TABLE['APPLIED_METHODOLOGY']}</p>
              </TableCell>
           
              <TableCell align='center' style={{ width: 50 }}>
                <span className='font-bold relative text-sm'>
                  {TABLE['ESTIMATED_REDUCTION']}
                  <Tooltip className='cursor-pointer' title={TOOLTIPS['ESTIMATED_REDUCTION']} placement='top' arrow>
                    <InfoIcon
                      className={`absolute -top-2 text-gray-400 ${isKorean ? '-right-4' : '-right-2'}`}
                      fontSize='10px'
                    />
                  </Tooltip>
                </span>
              </TableCell>
            
              {/* <TableCell align='center' style={{ minWidth: 45, maxWidth: 45 }}>
                {t('APPLICATION_TYPE')}
              </TableCell> */}
            
              <TableCell align='center' style={{ minWidth: 90, maxWidth: 90 }}>
              <p className='py-1'>{TABLE['PROJECT_STATUS']}</p>
              </TableCell>
              <TableCell align='center' style={{ minWidth: 70, maxWidth: 70 }}>
                {t('HISTORY')}
              </TableCell>
              <TableCell align='center' style={{ minWidth: 70 }}>
                {t('WORK')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, index) => (
                <TableRow
                  key={`table-row-${row.seq}-${index}`}
                  onClick={() => {
                    rowClick(index);
                  }}
                >
                  <TableCell align='center'>{row.seq}</TableCell>
                
                  <TableCell
                    align='left'
                    onClick={() => {
                      const { status, degree } = row;
                      callback('History', row);
                      switch (status) {
                        case 'CanceledProject':
                        case 'WritingProject': {
                          updateSnackbar({
                            type: 'error',
                            open: true,
                            message: '이력 조회 할 수 없는 사업입니다.',
                          });
                          break;
                        }
                        case 'WaitingForAuditProject': {
                          if (degree === 1) {
                            updateSnackbar({
                              type: 'error',
                              open: true,
                              message: '이력 조회 할 수 없는 사업입니다.',
                            });
                            break;
                          } else {
                            callback('History', row);
                            break;
                          }
                        }
                        default: {
                          callback('History', row);
                        }
                      }
                    }}
                  >
                    <span className='text-xs text-gray-400 mb-1'>ID: {row.id}</span>
                    
                 
                  </TableCell>
                  <TableCell align='center'>   <div className='hover:underline'>{row.title}</div></TableCell>
                  <TableCell align='center'>
                 {AREA_TYPE[row?.methodology?.area.toUpperCase()]}
                  </TableCell>
                  <TableCell align='center'>{row.startDate}</TableCell>
                  <TableCell align='center'>{row.endDate}</TableCell>
                  <TableCell align='center'>
                  {/* {t(Handler.renderProjectSubTypeText(row.subType))}   */}
                  {row.startDate && row.endDate
                      ? Handler.geni18nDatePeriod(isKorean, row.startDate, row.endDate)
                      : '-'}
                  </TableCell>              
                  <TableCell align='left' style={{ width: 180, minWidth: 180, maxWidth: 180 }}>
                    <Tooltip title={row.methodology?.title} placement='top' arrow>
                      <p>{Handler.textEllipsis(row.methodology?.title, 14)}</p>
                    </Tooltip>
                  </TableCell>
                
                  {/* <TableCell align='center'>
                    {row.reductionReady ? Handler.numberWithCommas(row.reductionReady) : '-'}
                  </TableCell> */}
                    <TableCell align='center'>
                    <p className='mr-1'>{`${Handler.numberWithCommas(row.reductionExpect)}`}</p>
                    <span className='text-xs text-gray-500'>tCO₂eq</span>
                  </TableCell>
                  <TableCell align='center'>
                  {PROJECT_STATUS[row.type]}
                    {/* {renderStatus(row.lastProjectDoc)} */}
                    </TableCell>
                  <TableCell align='center'>
                  
                    {renderHistoryButton(row.type)}
                    </TableCell>
                
                  <TableCell align='center'>{renderJob(row)}</TableCell>
                </TableRow>
              ))}
            {data && data.length === 0 && <NoDataRow colSpan={12} />}
          </TableBody>
        </Table>
      </TableContainer>
    </article>
  );
};
