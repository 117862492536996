import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useLabel } from '../../hooks';
import { useContext } from 'react';
import { LangContext } from '../../contexts';
import BackButton from '../../styles/icon/Feather Icon.svg';
import API from '../../api';



const styles = {
  paragraph: {
    fontSize: '16px',
    color: '#606369',
    fontWeight: 300,
    lineHeight: '26px',
  },
  button: {
    'color': '#999EAD',
    'backgroundColor': 'transparent',
    'border': 'none',
    '&:hover': {
      backgroundColor: 'white',
      border: 'none',
    },
  },
 
};

const RecruitmentDetails = () => {
  const { id} = useParams(); 
  const history = useHistory();
  const { RECRUITMENT_DETAILS } = useLabel();
  const [recruitmentDetails, setRecruitmentDetails] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const { isKorean } = useContext(LangContext);
  
  const fetchRecruitmentDetails = async (id) => {
    setSpinner(true);
    try {
      const { data } = await API.CS.GetRecruitDetail(id);
      // console.log("data please ------>", data);
      setRecruitmentDetails(data);
    } catch (error) {
      console.error('Failed to fetch recruitment details', error);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchRecruitmentDetails(id);
    }
  }, [id, history]);

  const handleBack = () => {
    history.push('/cs/recruitment');
  };

  if (!recruitmentDetails) {
    return <div></div>;
  }

  const renderSection = (label, content) => {
    return content ? (
      <>
        <p className='mb-4' style={{ color: '#5D38E5', fontWeight: 500, textDecoration: 'underline' }}>
          {label}
        </p>
        <p className='mb-5' style={{ color: '#606369', fontWeight: 300, lineHeight: '26px', fontSize: '15px' }} dangerouslySetInnerHTML={{ __html: content }}></p>
      </>
    ) : null;
  };

  return (
    <article className="content h-full">
      <article className='basic-page mt-[50px] flex'>
        <div className='w-[1200px]' style={{ justifyContent: 'flex-between', border: 'none' }}>
          <div>
          <Button
            style={{ ...styles.button, fontWeight: 300, lineHeight: '26px',  fontSize: '19px', marginLeft: 5 }}
            onClick={handleBack}
            
          >
            <img src={BackButton} alt='Back Button' style={{ fontSize: '14px', marginRight: '5px', fontWeight: 200, color: '#999EAD' }} />
            {isKorean ? '뒤로 가기' : 'Back'}
          </Button>
          </div>
          <div>
            <h2 className='mt-10' style={{ fontSize: '32px', paddingLeft: 20, lineHeight: '26px', color: '#222222', fontWeight: 400 }}>
           {RECRUITMENT_DETAILS['DIVISION']} {recruitmentDetails.title}
            </h2>
            <p className='mt-4' style={{ fontSize: '18px', paddingLeft: 20, lineHeight: '26px', color: '#332D48', fontWeight: 300 }}>
              {recruitmentDetails.dept}
            </p>
          </div>
        </div>
        <div className="styled-list mt-[-55px]" style={{ justifyContent: 'flex-end', marginLeft: '300px' }}>
          {renderSection(RECRUITMENT_DETAILS['RESPONSIBILITY'], recruitmentDetails.responsibility)}
          {renderSection(RECRUITMENT_DETAILS['QUALIFICATION'], recruitmentDetails.qualification)}
          {renderSection(RECRUITMENT_DETAILS['PREFERENCE'], recruitmentDetails.preference)}
          {renderSection(RECRUITMENT_DETAILS['APPLICATION-METHOD'], recruitmentDetails.applicationMethod)}
          {renderSection(RECRUITMENT_DETAILS['RECRUITMENT_PROCESS'], recruitmentDetails.recruitmentProcess)}
          {renderSection(RECRUITMENT_DETAILS['CONTACT'], recruitmentDetails.contact)}
        </div>
      </article>
    </article>
  );
};

export default RecruitmentDetails;

