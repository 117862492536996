import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import Utils from '../../api/Utils';
import {
  BasicTab,
  Button,
  CheckBox,
  DatePicker,
  DropdownInput,
  ModalCreditAction,
  ModalCreditView,
  PageHeader,
  SelectBox,
  TableCredit,
  TablePaging,
} from '../../components';
import { SnackbarContext } from '../../contexts';
import { useLabel, usePagination } from '../../hooks';
import { modalStyle } from '../../styles/style';

const getModeByStep = (tabIndex) => {
  if (tabIndex === 0) return 'Issue';
  if (tabIndex === 1) return 'Move';
  if (tabIndex === 2) return 'Offset';
  if (tabIndex === 3) return 'Retire';
  return null;
};

const getSmallModeByStep = (tabIndex) => {
  return getModeByStep(tabIndex).toLowerCase();
};

export const CreditListPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { DOC_PROCESS: DOC } = useLabel();
  const { updateSnackbar } = useContext(SnackbarContext);
  const [status, setStatus] = useState('SELECT_ALL');
  const [search, setSearch] = useState('');
  const [searchType, setSearchType] = useState('Title');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isAllDate, setIsAllDate] = useState(true);
  const [list, setList] = useState(null);
  const [page, totalPage, totalCount, onPageChange, setPage, setTotalPage, setTotalCount] = usePagination(0, 9);
  const [actionOpen, setActionOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedNextAction, setSelectedNextAction] = useState(null);
  const [lastSearchBody, setLastSearchBody] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [stat, setStat] = useState({
    issueCredit: 0,
    issueCreditAudit: 0,
    moveAssigneeCredit: 0,
    moveAssigneeCreditAudit: 0,
    moveAssignorCredit: 0,
    moveAssignorCreditAudit: 0,
    offsetCredit: 0,
    offsetCreditAudit: 0,
    reductionReady: 0,
    reductionSold: 0,
  });
  const [vrcStatus, setVrcStatus] = useState([]);

  //event
  const requestSearch = () => {
    setList([]);
    requestNewList(0);
  };

  const tableRowClick = (index) => {
    let target_seq = list[index].seq;
  };

  const tableCallback = (next_action, item) => {
    switch (next_action) {
      case 'History': {
        history.push('/credit/detail/' + item.seq);
        break;
      }
      case 'ModifyWriting': {
        history.push(`/credit/${item.mode.toLowerCase()}?seq=${item.seq}`);
        break;
      }
      case 'ImproveCommitteeStart':
      case 'ImproveZCCreditStart': {
        let target_seq = null;
        let target_mode = null;
        if (item) {
          target_seq = item.seq;
          target_mode = item.mode;
        }
        if (selectedItem) {
          target_seq = selectedItem.seq;
          target_mode = selectedItem.mode;
        }
        API.Credit.PostNextAction(target_seq, {
          nextAction: next_action,
        })
          .then((res) => {
            closeActionModal();
            closeViewModal();
            requestNewList();
            setSelectedAction({
              status: 'RequestMoveWriting',
              seq: target_seq,
              mode: target_mode,
            });
            setViewOpen(true);
          })
          .catch((err) => {
            updateSnackbar({
              type: 'error',
              open: true,
              message: err.data.message,
            });
          });
        break;
      }
      case 'View': {
        setSelectedItem(item);
        setSelectedAction(item.lastCreditDoc.actionSeq);
        setViewOpen(true);
        break;
      }
      case 'CancelWaiting': {
        setSelectedItem(item);
        setSelectedAction(item.lastCreditDoc.actionSeq);
        setSelectedNextAction(next_action);
        setActionOpen(true);
        break;
      }
      default: {
        setSelectedItem(item);
        setSelectedAction(item.lastCreditDoc.actionSeq);
        setSelectedNextAction(next_action);
        setActionOpen(true);
      }
    }
  };

  const handleModalRequest = (body) => {
    API.Credit.PostNextAction(selectedItem.seq, body).then(
      (res) => {
        let result = res.data;
        console.log('PostNextAction  result', result);
        closeActionModal();
        requestNewList();
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  const closeActionModal = () => {
    setActionOpen(false);
    setSelectedItem(null);
    setSelectedAction(null);
    setSelectedNextAction(null);
  };

  const closeViewModal = () => {
    setViewOpen(false);
    setSelectedItem(null);
    setSelectedAction(null);
    setSelectedNextAction(null);
  };

  //server
  const requestNewList = (init_page) => {
    let params = null;
    if (init_page === 0) {
      params = {
        mode: getModeByStep(tabIndex),
        page: init_page ? init_page : page,
        size: Handler.VARIABLES.PAGE_LIMIT,
        searchType: searchType,
        searchValue: search,
      };
      if (status !== 'SELECT_ALL') {
        params.status = status;
      }
      if (!isAllDate) {
        if (startDate) {
          params.startDate = Handler.dbDashDateToCommaDate(startDate);
        }
        if (endDate) {
          params.endDate = Handler.dbDashDateToCommaDate(endDate);
        }
      }
      setLastSearchBody({ ...params });
    } else {
      params = {
        ...lastSearchBody,
        page: init_page ? init_page : page,
        size: Handler.VARIABLES.PAGE_LIMIT,
      };
    }
    API.Credit.GetList(params)
      .then((res) => {
        const { content, totalPages } = res.data;
        const list = content.filter((item) => item.lastCreditDoc.actionSeq);
        setList(list);
        setTotalPage(totalPages);
      })
      .catch((err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      });

    API.Credit.GetStat().then(
      (res) => {
        let result = res.data;
        setStat(result);
      },
      (err) => {
        updateSnackbar({
          type: 'error',
          open: true,
          message: err.data.message,
        });
      },
    );
  };

  //hook
  useEffect(() => {
    if (location && location.search) {
      const params = {
        ...Utils.ParamsToObject(history.location.search),
      };
      setTabIndex(Number(params.tabIndex));
    }
  }, []);

  useEffect(() => {
    setPage(0);
    requestNewList(0);
    history.replace({
      search: Utils.ObjectToParams({
        tabIndex: tabIndex,
      }),
    });
  }, [tabIndex]);

  useEffect(() => {
    if (lastSearchBody) {
      requestNewList(page);
    }
  }, [page]);

  useEffect(() => {
    setVrcStatus([
      {
        mode: 'move',
        label: 'TRANSFER',
        amount: [
          {
            label: 'TOTAL_TRANFER_QUANTITY',
            vrc: Handler.numberWithCommas(stat.moveAssignorCredit),
            audit: Handler.numberWithCommas(stat.moveAssignorCreditAudit),
          },
          {
            label: 'TOTAL_RECEIVING_QUANTITY',
            vrc: Handler.numberWithCommas(stat.moveAssigneeCredit),
            audit: Handler.numberWithCommas(stat.moveAssigneeCreditAudit),
          },
        ],
      },
      {
        mode: 'offset',
        label: 'RETIREMENT',
        amount: [
          {
            label: 'TOTAL_QUANTITY',
            vrc: Handler.numberWithCommas(stat.offsetCredit),
            audit: Handler.numberWithCommas(stat.offsetCreditAudit),
          },
        ],
      },
      {
        mode: 'retire',
        label: 'CANCELLATION',
        amount: [
          {
            label: 'TOTAL_QUANTITY',
            vrc: Handler.numberWithCommas(stat.retireCredit),
            audit: Handler.numberWithCommas(stat.retireCreditAudit),
          },
        ],
      },
    ]);
  }, [stat]);

  return (
    <article className='basic-page'>
      <PageHeader type='credit' title={t('VRC_CREDIT_STATUS')}></PageHeader>
      <article className='content__wrapper'>
        <div className='stat__container'>
          <ul>
            <li>
              <div className='title__box'>
                <h2 className='font-bold'>{t('VRC_CREDIT_STATUS')}</h2>
              </div>
              <div className='flex justify-between w-full'>
                <section className='w-1/3'>
                  <header>
                    <span className='font-bold'>{t('GAS_REDUCTIONS')}</span>
                  </header>
                  <div className='flex items-center mt-2'>
                    <h2 className='text-red-500 font-bold'>{Handler.numberWithCommas(stat.reductionReady)}</h2>
                    <span className='ml-1 text-gray-600'>tCO2-eq</span>
                    {stat.reductionReady > 0 && (
                      <div className='ml-2 caption__box'>
                        <Tooltip title='크레딧 발급을 신청해주세요!'>
                          <div className='ico tri'></div>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </section>
                <section className='w-1/3'>
                  <header>
                    <span className='font-bold'>{t('ISSUE_QUANTITY')}</span>
                  </header>
                  <div className='flex items-center my-2'>
                    <h2 className='text-blue-600 font-bold'>{Handler.numberWithCommas(stat.issueCredit)}</h2>
                    <span className='ml-1 text-gray-600'>{t('CREDIT')}</span>
                  </div>
                  <div className='caption__box'>
                    <span className='text-sm'>{t('REVIEW')}</span>
                    <span className='font-bold mx-1'>{Handler.numberWithCommas(stat.issueCreditAudit)}</span>
                    <span className='text-sm'>{t('CREDIT')}</span>
                  </div>
                </section>
                <section className='w-1/3'>
                  <header>
                    <span className='font-bold'>{t('SALES_VOLUME')}</span>
                  </header>
                  <div className='flex items-center my-2'>
                    <h2 className='text-blue-600 font-bold'>{Handler.numberWithCommas(stat.reductionSold)}</h2>
                    <span className='ml-1 text-gray-600'>{t('CREDIT')}</span>
                  </div>
                </section>
              </div>
            </li>
          </ul>
        </div>
        <div className='stat__container'>
          <ul>
            {vrcStatus.length > 0 &&
              vrcStatus.map((vrc, idx) => {
                return (
                  <li key={vrc.label}>
                    <div className='title__box'>
                      <h2 className='font-bold'>
                        {t('CREDIT')} {t(vrc.label)}
                      </h2>
                    </div>
                    <div className='value__box'>
                      {vrc.amount.map((item, idx) => {
                        return (
                          <section key={item.label} className='vluae-item'>
                            <header>
                              <span>{t(item.label)}</span>
                            </header>
                            <div className='credit__box'>
                              <h2>{item.vrc}</h2>
                              <em>{t('CREDIT')}</em>
                            </div>
                            <div className='caption__box'>
                              <em>{t('REVIEW')}</em>
                              <b>{item.audit}</b>
                              <em>{t('CREDIT')}</em>
                            </div>
                          </section>
                        );
                      })}
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        <div style={{ height: 40 }}></div>
        <BasicTab
          index={tabIndex}
          meta={{
            menus: [
              'ISSUE_REQUEST_STATUS',
              'TRANSFER_REQUEST_STATUS',
              'WITHDRAWAL_REQUEST_STATUS',
              'DISPOSAL_REQUEST_STATUS',
            ],
          }}
          onChange={(value) => {
            setTabIndex(value);
          }}
        ></BasicTab>
        <div className='search__container between'>
          <div className='select__box'>
            <SelectBox
              value={status}
              onChange={(value) => {
                setStatus(value);
              }}
              meta={{
                key: 'status',
                style: {
                  // width: 330,
                },
                menus: [
                  {
                    label: t('STATUS') + ' ' + t('ALL'),
                    value: 'SELECT_ALL',
                  },
                  {
                    label: t('TEMP_SAVE'),
                    value: 'Writing',
                  },
                  {
                    label: DOC['WAITING'],
                    value: 'Waiting',
                  },
                  {
                    label: DOC['REVIEW'],
                    value: 'Audit',
                  },
                  {
                    label: DOC['REQ_FOR_REVISION'],
                    value: 'ImprovementRequest',
                  },
                  {
                    label: `${DOC['FINAL_APPROVED']} / ${DOC['COMMENCEMENT_APPROVED']}`,
                    value: 'Approved',
                  },
                  {
                    label: DOC['REJECTED'],
                    value: 'Rejected',
                  },
                  {
                    label: t('CANCEL'),
                    value: 'Canceled',
                  },
                ],
              }}
            ></SelectBox>
          </div>
          <div className='date__box'>
            <DatePicker
              value={startDate}
              meta={{
                style: {
                  maxWidth: '180px',
                },
              }}
              onChange={(e) => {
                console.log('e', e);
                setStartDate(e);
              }}
            ></DatePicker>
            <span style={{ margin: '0px 5px' }}>~</span>
            <DatePicker
              value={endDate}
              meta={{
                style: {
                  maxWidth: '180px',
                },
              }}
              onChange={(e) => {
                console.log('e', e);
                setEndDate(e);
              }}
            ></DatePicker>
            <CheckBox
              meta={{
                label: 'ENTIRE_PERIOD',
                style: {
                  marginLeft: 10,
                },
                className: 'square',
              }}
              value={isAllDate}
              onChange={(new_value, key) => {
                console.log('e', new_value);
                setIsAllDate(new_value);
              }}
            ></CheckBox>
          </div>
          <div className='input__box'>
            <DropdownInput
              value={search}
              menuValue={searchType}
              onChange={(value, key) => {
                setSearch(value);
              }}
              onMenuChange={(value, key) => {
                console.log('menu', value);
                setSearchType(value);
              }}
              onEnter={() => {
                requestSearch();
              }}
              meta={{
                style: { width: '300px' },
                placeholder: 'KEYWORD',
                menus: [
                  {
                    value: 'Title',
                    label: 'BIZ_TITLE',
                  },
                  {
                    value: 'Id',
                    label: 'BIZ_ID',
                  },
                  {
                    value: 'Amount',
                    label: 'AMOUNT',
                  },
                ],
              }}
            ></DropdownInput>
            <Button
              style={{ width: '80px', height: '50px', marginLeft: 12 }}
              onClick={() => {
                requestSearch();
              }}
              label='SEARCH'
            ></Button>
          </div>
        </div>
        <TableCredit
          mode={getSmallModeByStep(tabIndex)}
          data={list}
          rowClick={tableRowClick}
          callback={tableCallback}
        ></TableCredit>
        <div style={{ height: 40 }}></div>
        <TablePaging page={page} totalPage={totalPage} onChange={onPageChange}></TablePaging>
      </article>
      <Modal open={actionOpen} onClose={closeActionModal}>
        <Box sx={modalStyle}>
          <ModalCreditAction
            mode={getSmallModeByStep(tabIndex)}
            action={selectedAction}
            nextAction={selectedNextAction}
            requestAction={handleModalRequest}
            onClose={closeActionModal}
          ></ModalCreditAction>
        </Box>
      </Modal>
      <Modal open={viewOpen} onClose={closeViewModal}>
        <Box sx={modalStyle}>
          <ModalCreditView
            mode={getSmallModeByStep(tabIndex)}
            action={selectedAction}
            onClose={closeViewModal}
            requestAction={tableCallback}
          ></ModalCreditView>
        </Box>
      </Modal>
    </article>
  );
};
