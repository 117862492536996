import axios from 'axios';
import { BASE_URL } from './Config';
import Utils from './Utils';

const AxiosInstance = axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },
});

const clearToken = () => {
  Utils.RemoveStorage('accessToken');
  Utils.RemoveStorage('refreshToken');
  Utils.RemoveStorage('keeping');
};

let refreshingToken = null;

// 요청 인터셉터 추가
AxiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = Utils.GetStorage('accessToken')
    if (accessToken) {
      config.headers['X-AUTH-TOKEN'] = accessToken
    }

    return config
  },
  (error) => {
    console.log('interceptors request error', error)
    return Promise.reject(error)
  },
)

// 응답 인터셉터 추가
AxiosInstance.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    console.log('axios error', error);

    if (!error.response) {
      alert('서비스 점검');
      // clearToken();
      window.location.href = '/error';

      return Promise.reject(error);
    }

    const prevRequest = error.config;
    const refreshTokenMessage = error.response?.data?.message;

    if (refreshTokenMessage === 'Refresh JWT 토큰형식이 일치하지 않습니다.' && !prevRequest?.sent) {
      prevRequest.sent = true;

      if (!refreshingToken) {
        refreshingToken = Utils.reissueAccessToken(); // 토큰 갱신 요청 시작
      }

      try {
        await refreshingToken; // 기존에 진행 중인 토큰 갱신을 기다림

        return AxiosInstance(prevRequest);
      } catch (err) {
        clearToken();

        return Promise.reject(err.response);
      } finally {
        refreshingToken = null; // 토큰 갱신 완료 후 변수 초기화
      }
    } else if (
      refreshTokenMessage === 'Refresh 토큰 인증이 만료되었습니다.' ||
      refreshTokenMessage === '존재하지 않는 Refresh JWT 입니다. 다시 로그인 해주세요.'
    ) {
      alert('세션이 만료되었습니다. 다시 로그인해주세요.');
      clearToken();
      window.location.href = '/user/login';
    }
    console.log('interceptors response error', error);

    return Promise.reject(error.response);
  },
);

export default AxiosInstance;
