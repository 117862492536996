export const ROUTE_PATH = [
  {
    text: 'REGISTRY',
    activePath: 'registry',
    child: [
      {
        text: 'METHODOLOGY',
        path: '/registry/methodology/list',
      },
      {
        text: 'PROJECT',
        path: '/registry/project/list',
      },
      {
        text: 'VRC_CREDIT',
        path: '/registry/credit/list',
      },
      {
        text: 'TERM',
        path: '/registry/terms',
      },
    ],
  },
  {
    text: 'PROGRAM',
    activePath: 'program',

    child: [
      {
        text: 'POPLE_STANDARD',
        path: '/program/standard',
      },

      {
        text: 'STANDARD_DOCUMENT',
        path: '/program/information',
      },

      {
        text: 'VERIFICATION',
        path: '/program/verification',
      },
      {
        text: 'DOCUMENT',
        path: '/program/document',
      },
    ],
  },
  {
    text: 'OPEN_COMMENT',
    activePath: 'consultation',
    child: [
      {
        text: 'LIST',
        path: '/consultation/list',
      },
    ],
  },
  {
    text: 'METHODOLOGY',
    activePath: 'methodology',
    child: [
      {
        text: 'CONSULTATION_REQUEST',
        path: '/methodology/request',
      },
      {
        text: 'METHODOLOGY_STATUS',
        path: '/methodology/list',
      },
    ],
  },
  {
    text: 'PROJECT',
    activePath: 'project',
    child: [
      {
        text: 'PROJECT_CONSULTATION',
        path: '/project/request',
      },
      {
        text: 'PROJECT_APPLY',
        path: '/project/writing/',
      },
      {
        text: 'PROJECT_STATUS',
        path: '/project/list',
      },
    ],
  },
  {
    text: 'REDUCTION',
    activePath: 'reduction',
    child: [
      {
        type: 'action',
        text: 'REDUCTION_APPLY',
        action: 'REQUEST_REDUCTION',
      },
      {
        text: 'REDUCTION_STATUS',
        path: '/reduction/list',
      },
    ],
  },
  {
    text: 'VRC_CREDIT',
    activePath: 'credit',
    child: [
      {
        text: 'VRC_ISSUANCE',
        path: '/credit/issue',
      },
      {
        text: 'VRC_TRANSFER',
        path: '/credit/move',
      },
      {
        text: 'VRC_WITHDRAWAL',
        path: '/credit/offset',
      },
      {
        text: 'VRC_DISPOSAL',
        path: '/credit/retire',
      },
      {
        text: 'VRC_STATUS',
        path: '/credit/list',
      },
    ],
  },
];

export const CS_PATH = [
  {
    text: 'NOTICE',
    path: '/cs/notice',
  },
  {
    text: 'BOARD',
    activePath: 'board',
    child: [
      {
        text: 'BOARD_DETAILS',
        path: '/cs/board/details/:id',
      },
      {
        text: 'BOARD',
        path: '/cs/board',
      },
    ],
  },
  {
    text: 'FAQ',
    path: '/cs/faq',
  },
  {
    text: 'Contact us',
    path: '/cs/contact_us',
  },
  {
    text: 'Grievence Submission',
    path: '/cs/grievance',
  },
  {
    text: '채용공고',
    activePath: 'recruitments',
    child: [
      {
        text: 'recruitments',
        path: '/cs/recruitments',

      },
      {
        text: 'RECRUITMENT_DETAILS',
        path: '/cs/recruitments/:id',
      },
    ],
  }
];
