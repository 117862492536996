import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { PageHeader } from '../../components';
import { LangContext } from '../../contexts';
import { useLabel } from '../../hooks';
import RegistrationProcess from '../../styles/images/RegistrationProcess.svg';
import RegistrationProcessEn from '../../styles/images/RegistrationProcessEn.svg';

const styles = {
  paragraph: {
    fontSize: '16px',
    color: '#606369',
    fontWeight: 300,
  },
  sideBar: {
    position: 'sticky',
    display: 'block',
    top: '200px',
    margin: 25,
    width: '218px',
  },
  button: {
    borderRadius: '0',
    height: 44,
    width: 'inherit',
    textWrap: 'no-wrap',
    marginRight: '10px',
    fontSize: '15px',
    lineHeight: '20px',
    padding: '10px 20px',
  },
};
export const ProgramDetailsPage = (props) => {
  const { isKorean } = useContext(LangContext);
  const { STANDARD_DOCUMENT_PAGE } = useLabel();
  const { PROGRAM_DETAILS_PAGE } = useLabel();
  const { t } = useTranslation();
  const [activeLink, setActiveLink] = useState('');
  const history = useHistory();
  const location = useLocation();
  const handleNavClick = (sectionId) => {
    const section = document.getElementById(sectionId);

    if (section) {
      const headerOffset = 200;
      const sectionPosition = section.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = sectionPosition - headerOffset;
      setActiveLink(sectionId);
      window.scrollTo({
        behavior: 'smooth',
        top: offsetPosition,
      });
    }
  };

  useEffect(() => {
    setActiveLink('register-procedure');
  }, []);

  const handleSignUpClick = () => {
    history.push('/user/signup');
  };
  const handleInquiries = () => {
    history.push('/cs/contact_us');
  };

  const inactiveLinkStyle = {
    color: '#999EAD',
    fontWeight: 400,
  };
  const activeLinkStyle = {
    color: '#5D38E5',
    fontWeight: 600,
    overflowWrap: 'normal',
    wordBreak: 'keep-all',
  };

  const handleGotoFaq = () => {
    history.push('/cs/faq');
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.target.id) {
            setActiveLink(entry.target.id);
          }
        });
      },
      { threshold: 0.4, rootMargin: '90px 0px -50% 0px' },
    );

    const sections = document.querySelectorAll('section');
    sections.forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (location.hash) {
      const scrollToSafeguard = location.hash.split('#')[1];

      handleNavClick(scrollToSafeguard);
      const targetElement = document.getElementById(scrollToSafeguard);
      console.log('TE (in details page) -------->', targetElement);

      if (targetElement.id === 'pre-review') {
        console.log('ID: ', targetElement.id);
        window.scrollTo({
          top: targetElement.offsetTop - 230,
          behavior: 'smooth',
        });
      }
    }
  }, [location]);

  return (
    <article className='h-full content'>
      <article className='basic-page'>
        <article className='basic-page bg-[#FAF9FE] h-full pb-6'>
          <PageHeader type='standard'>
            <div className='flex justify-between w-full'>
              <div>
                <h1 className='text-[36px]'>{`${PROGRAM_DETAILS_PAGE['TITLE']}`}</h1>
              </div>
              <div className='leading-7 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                <p className='leading-7 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                  {PROGRAM_DETAILS_PAGE['DESCRIPTION']}
                </p>
                <p className='mt-5 leading-7 whitespace-pre-wrap description'>
                  {PROGRAM_DETAILS_PAGE['DESCRIPTION_BOTTOM']}
                </p>
                <div className='mt-10'>
                  <Button
                    variant='contained'
                    style={{ borderRadius: '0', height: 56, fontSize: '16px', textWrap: 'nowrap', width: 'inherit' }}
                    component='a'
                    target='_blank'
                    rel='noopener noreferrer'
                    sx={{
                      'outline': '#5D38E5',
                      'border': '#5D38E5',
                      'padding': '16px 24px',
                      'boxShadow': 0,
                      '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: '  #8E74ED',
                        color: '#FFFFFF',
                        border: '0px solid #8E74ED',
                        outline: '0px solid #8E74ED',
                      },
                      '&:active': {
                        backgroundColor: '#5D38E5',
                        border: '0px solid #5D38E5',
                        outline: '0px solid #5D38E5',
                        color: '#FFFFFF',
                      },
                    }}
                    href={
                      isKorean
                        ? 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/ko/[POPLE] 프로젝트 등록 및 발행 매뉴얼.docx'
                        : 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/[POPLE] POPLE Project Registration and issuance manual.pdf'
                    }
                  >
                    {isKorean ? '프로젝트 등록 및 발급절차.pdf' : 'Project Registration and Issuance Process.docx'}
                  </Button>
                </div>
              </div>
            </div>
          </PageHeader>
        </article>
        <div className='row-page-row py-[80px] ' style={{ display: 'flex', top: '200px' }}>
          <div className='mr-12 fixed-sidebar'>
            <nav className='fixed-sidebar' style={styles.sideBar}>
              <div
                style={{
                  borderRight: '1px solid #D7DAE2',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                  paddingRight: 24,
                }}
              >
                <Button
                  onClick={() => handleNavClick('register-procedure')}
                  style={activeLink === 'register-procedure' ? styles.activeLink : inactiveLinkStyle}
                  className='mb-6 leading-8'
                  sx={{
                    width: isKorean ? 'inherit' : '150px',
                    textAlign: 'right',
                  }}
                >
                  {isKorean ? '프로젝트 등록 절차' : 'Project Registration Process'}
                </Button>
                <Button
                  onClick={() => handleNavClick('create-account')}
                  style={activeLink === 'create-account' ? styles.activeLink : inactiveLinkStyle}
                >
                  {isKorean ? '계정 생성' : 'Account Creation'}
                </Button>
                <Button
                  onClick={() => handleNavClick('pre-review')}
                  style={activeLink === 'pre-review' ? styles.activeLink : inactiveLinkStyle}
                  sx={{
                    width: isKorean ? 'inherit' : '150px',
                    textAlign: 'right',
                  }}
                >
                  {isKorean ? '프로젝트 사전 검토' : 'Preliminary Project\nReview'}
                </Button>
                <Button
                  onClick={() => handleNavClick('registration')}
                  style={activeLink === 'registration' ? styles.activeLink : inactiveLinkStyle}
                >
                  {isKorean ? '프로젝트 등재' : 'Project Listing'}
                </Button>

                <Button
                  onClick={() => handleNavClick('assessment')}
                  style={activeLink === 'assessment' ? styles.activeLink : inactiveLinkStyle}
                >
                  {isKorean ? '프로젝트 타당성 평가' : 'Validation'}
                </Button>
                <Button
                  onClick={() => handleNavClick('verification')}
                  style={activeLink === 'verification' ? styles.activeLink : inactiveLinkStyle}
                >
                  {isKorean ? '프로젝트 검증' : 'Verification'}
                </Button>
                <Button
                  onClick={() => handleNavClick('vrc-certification')}
                  style={activeLink === 'vrc-certification' ? styles.activeLink : inactiveLinkStyle}
                >
                  {isKorean ? '프로젝트 인증' : 'VRC Certification'}
                </Button>
                <Button
                  onClick={() => handleNavClick('vrc-issuance')}
                  style={activeLink === 'vrc-issuance' ? styles.activeLink : inactiveLinkStyle}
                >
                  {isKorean ? 'VRC(크레딧) 발행' : 'VRC Issuance'}
                </Button>
                <Button
                  onClick={() => handleNavClick('faq')}
                  style={activeLink === 'faq' ? styles.activeLink : inactiveLinkStyle}
                >
                  {isKorean ? 'FAQ' : 'FAQ'}
                </Button>
              </div>
            </nav>
          </div>

          <div className='w-full pl-5 content-section'>
            <section className='w-full py-[30px]'>
              <section id='register-procedure' data-anchor='register-procedure'>
                <article className='pb-12 '>
                  <div className='mb-8'>
                    <p
                      className='mb-6 leading-8'
                      style={{ fontWeight: '500', fontSize: '20px' }}
                      sx={{
                        width: '150px',
                        textAlign: 'right',
                      }}
                    >
                      {isKorean ? '프로젝트 등록 절차' : 'Project Registration Process'}
                    </p>

                    {isKorean ? (
                      <img src={RegistrationProcess} alt='프로세스 등록 절차' className='mb-8' />
                    ) : (
                      <img src={RegistrationProcessEn} alt='RegistrationProcess' className='mb-8' />
                    )}
                  </div>
                </article>
              </section>
              <section id='create-account' data-anchor='account-creation'>
                <div className='pb-12 mb-12'>
                  <p className='mb-8 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                    {isKorean ? '계정 생성' : 'Account Creation'}
                  </p>
                  <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                    {PROGRAM_DETAILS_PAGE['CREATE_ACCOUNT']}
                  </p>
                  <div className='flex justify-start mb-7'>
                    <Button
                      variant='outlined'
                      onClick={handleSignUpClick}
                      style={{ ...styles.button, width: isKorean ? '154px' : '175px', textWrap: 'nowrap' }}
                      sx={{
                        'border': '1px solid #5D38E5',
                        '&:hover': {
                          boxShadow: 'none',
                          color: '#8E74ED',
                          border: '1px solid',
                          outline: '0px solid #8E74ED',
                          background: 'transparent',
                        },
                        '&:active': {
                          border: '1px solid',
                          color: '#8E74ED',
                          outline: '0px solid #8E74ED',
                          background: 'transparent',
                        },
                      }}
                    >
                      {isKorean ? '계정 생성 하기' : 'Create Account'} {<ArrowForwardIcon fontSize='small' />}
                    </Button>
                  </div>
                </div>
              </section>
              <section id='pre-review'>
                <article className='pb-12'>
                  <div className='mb-[100px]'>
                    <p className='leading-6 mb-9' style={{ fontWeight: '500', fontSize: '20px' }}>
                      {PROGRAM_DETAILS_PAGE['PRE-REVIEW']['TITLE']}
                    </p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['PRE-REVIEW']['DESCRIPTION']}
                    </p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['PRE-REVIEW']['SUB_DESCRIPTION']}
                    </p>
                    <p
                      className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap'
                      style={{ ...styles.paragraph }}
                      dangerouslySetInnerHTML={{ __html: PROGRAM_DETAILS_PAGE['PRE-REVIEW']['SUB_DESCRIPTION_2'] }}
                    ></p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['PRE-REVIEW']['SUB_DESCRIPTION_3']}
                    </p>
                    <div className='flex justify-start mb-[65px]'>
                      <Button
                        variant='outlined'
                        style={{ ...styles.button, textWrap: 'nowrap', width: isKorean ? '213px' : '318px' }}
                        component='a'
                        target='_blank'
                        rel='noopener noreferrer'
                        sx={{
                          'border': '1px solid #5D38E5',
                          '&:hover': {
                            boxShadow: 'none',
                            color: '#8E74ED',
                            border: '1px solid',
                            outline: '0px solid #8E74ED',
                            background: 'transparent',
                          },
                          '&:active': {
                            border: '1px solid',
                            color: '#8E74ED',
                            outline: '0px solid #8E74ED',
                            background: 'transparent',
                          },
                        }}
                        href={
                          isKorean
                            ? 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/ko/[POPLE] 프로젝트 계획서 양식.docx'
                            : 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/[POPLE] Project Design Document.docx'
                        }
                      >
                        {isKorean ? '프로젝트 계획서(PDD).docx' : 'Project Design Document Template.docx'}
                      </Button>
                    </div>
                    <p
                      className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap'
                      style={{ ...styles.paragraph }}
                      dangerouslySetInnerHTML={{ __html: PROGRAM_DETAILS_PAGE['PRE-REVIEW']['SUB_DESCRIPTION_4'] }}
                    ></p>
                    <p
                      className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap'
                      style={{ ...styles.paragraph }}
                      dangerouslySetInnerHTML={{ __html: PROGRAM_DETAILS_PAGE['PRE-REVIEW']['SUB_DESCRIPTION_5'] }}
                    ></p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['PRE-REVIEW']['SUB_DESCRIPTION_6']}
                    </p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['PRE-REVIEW']['SUB_DESCRIPTION_7']}
                    </p>
                    <div
                      className='flex justify-start'
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        paddingRight: '30px',
                        width: isKorean ? 'inherit' : '880px',
                      }}
                    >
                      <div>
                        <Button
                          variant='outlined'
                          style={{ ...styles.button, width: 'inherit', flexBasis: isKorean ? '' : '50%' }}
                          component='a'
                          target='_blank'
                          rel='noopener noreferrer'
                          href={
                            isKorean
                              ? 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/ko/[POPLE] 방법론 개발 및 등록 매뉴얼.docx'
                              : 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/[POPLE] POPLE Methodology Development and Registration manual.pdf'
                          }
                          sx={{
                            'border': '1px solid #5D38E5',
                            '&:hover': {
                              boxShadow: 'none',
                              color: '#8E74ED',
                              border: '1px solid',
                              outline: '0px solid #8E74ED',
                              background: 'transparent',
                            },
                            '&:active': {
                              border: '1px solid',
                              color: '#8E74ED',
                              outline: '0px solid #8E74ED',
                              background: 'transparent',
                            },
                          }}
                        >
                          {isKorean
                            ? '방법론 개발 및 등록 프로세스.pdf'
                            : 'Methodology Development and Review Manual.pdf'}
                        </Button>
                        <Button
                          variant='outlined'
                          style={{ ...styles.button }}
                          component='a'
                          target='_blank'
                          rel='noopener noreferrer'
                          sx={{
                            'border': '1px solid #5D38E5',
                            '&:hover': {
                              boxShadow: 'none',
                              color: '#8E74ED',
                              border: '1px solid',
                              outline: '0px solid #8E74ED',
                              background: 'transparent',
                            },
                            '&:active': {
                              border: '1px solid',
                              color: '#8E74ED',
                              outline: '0px solid #8E74ED',
                              background: 'transparent',
                            },
                          }}
                          href={
                            isKorean
                              ? 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/ko/[POPLE] POPLE_방법론 제안서 양식.docx'
                              : 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/[POPLE] POPLE_Methodology Proposal.docx'
                          }
                        >
                          {isKorean ? '방법론 제안서.docx' : 'Methodology Proposal Template.docx'}
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant='outlined'
                          style={{
                            ...styles.button,
                            flexBasis: isKorean ? '' : '50%',
                          }}
                          sx={{
                            'border': '1px solid #5D38E5',
                            '&:hover': {
                              boxShadow: 'none',
                              color: '#8E74ED',
                              border: '1px solid',
                              outline: '0px solid #8E74ED',
                              background: 'transparent',
                            },
                            '&:active': {
                              border: '1px solid',
                              color: '#8E74ED',
                              outline: '0px solid #8E74ED',
                              background: 'transparent',
                            },
                          }}
                          component='a'
                          target='_blank'
                          rel='noopener noreferrer'
                          href={
                            isKorean
                              ? 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/ko/[POPLE] 방법론 아이디어 노트 양식.docx'
                              : 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/[POPLE] Methodology Idea Note.docx'
                          }
                        >
                          {isKorean ? '방법론 아이디어 노트.docx' : 'Methodology Idea Note Template.docx'}
                          {!isKorean && <div />}
                        </Button>

                        <Button
                          variant='outlined'
                          style={{ ...styles.button }}
                          component='a'
                          target='_blank'
                          rel='noopener noreferrer'
                          sx={{
                            'border': '1px solid #5D38E5',
                            '&:hover': {
                              boxShadow: 'none',
                              color: '#8E74ED',
                              border: '1px solid',
                              outline: '0px solid #8E74ED',
                              background: 'transparent',
                            },
                            '&:active': {
                              border: '1px solid',
                              color: '#8E74ED',
                              outline: '0px solid #8E74ED',
                              background: 'transparent',
                            },
                          }}
                          href={
                            isKorean
                              ? 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/ko/[POPLE] 방법론 컨셉 노트 양식.docx'
                              : 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/[POPLE] Methodology Concept Note.docx'
                          }
                        >
                          {isKorean ? '방법론 컨셉 노트.docx' : 'Methodology Concept Note Template.docx'}
                        </Button>
                      </div>
                    </div>
                  </div>
                </article>
              </section>
              <section id='registration'>
                <article className='pb-12 '>
                  <div className='mb-8'>
                    <div>
                      <p className='mb-8 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                        {PROGRAM_DETAILS_PAGE['PROJECT_REGISTRATION']['TITLE']}
                      </p>
                      <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                        {PROGRAM_DETAILS_PAGE['PROJECT_REGISTRATION']['DESCRIPTION']}
                      </p>
                    </div>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['PROJECT_REGISTRATION']['SUB_DESCRIPTION']}
                    </p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['PROJECT_REGISTRATION']['SUB_DESCRIPTION_1']}
                    </p>
                    <p
                      className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap'
                      style={{ ...styles.paragraph }}
                      dangerouslySetInnerHTML={{
                        __html: PROGRAM_DETAILS_PAGE['PROJECT_REGISTRATION']['SUB_DESCRIPTION_2'],
                      }}
                    ></p>
                    <div
                      className='flex justify-start'
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        paddingRight: '30px',
                        width: isKorean ? 'inherit' : '880px',
                      }}
                    >
                      <div>
                        <Button
                          variant='outlined'
                          style={{ ...styles.button, flexBasis: isKorean ? '' : '50%' }}
                          component='a'
                          target='_blank'
                          rel='noopener noreferrer'
                          sx={{
                            'width': isKorean ? '176px' : '245px',
                            'border': '1px solid #5D38E5',
                            '&:hover': {
                              boxShadow: 'none',
                              color: '#8E74ED',
                              border: '1px solid',
                              outline: '0px solid #8E74ED',
                              background: 'transparent',
                            },
                            '&:active': {
                              border: '1px solid',
                              color: '#8E74ED',
                              outline: '0px solid #8E74ED',
                              background: 'transparent',
                            },
                          }}
                          href={
                            isKorean
                              ? 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/ko/[POPLE] 사전검토보고서 양식.docx'
                              : 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/[POPLE] Preliminary Review Form.docx'
                          }
                        >
                          {isKorean ? '사전 검토 보고서.docx' : 'Preliminary Review Form.docx'}
                        </Button>
                        <Button
                          variant='outlined'
                          style={{ ...styles.button }}
                          component='a'
                          target='_blank'
                          rel='noopener noreferrer'
                          href={
                            isKorean
                              ? 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/ko/[POPLE] 프로젝트 계획서 양식.docx'
                              : 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/[POPLE] Project Design Document.docx'
                          }
                          sx={{
                            'width': isKorean ? '213px' : '251px',
                            'border': '1px solid #5D38E5',
                            '&:hover': {
                              boxShadow: 'none',
                              color: '#8E74ED',
                              border: '1px solid',
                              outline: '0px solid #8E74ED',
                              background: 'transparent',
                            },
                            '&:active': {
                              border: '1px solid',
                              color: '#8E74ED',
                              outline: '0px solid #8E74ED',
                              background: 'transparent',
                            },
                          }}
                        >
                          {isKorean ? ' 프로젝트 계획서(PDD).docx' : 'Project Design Document.docx'}
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant='outlined'
                          style={{ ...styles.button }}
                          component='a'
                          target='_blank'
                          sx={{
                            'width': isKorean ? '172px' : '251px',
                            'border': '1px solid #5D38E5',
                            '&:hover': {
                              boxShadow: 'none',
                              color: '#8E74ED',
                              border: '1px solid',
                              outline: '0px solid #8E74ED',
                              background: 'transparent',
                            },
                            '&:active': {
                              border: '1px solid',
                              color: '#8E74ED',
                              outline: '0px solid #8E74ED',
                              background: 'transparent',
                            },
                          }}
                          rel='noopener noreferrer'
                          href={
                            isKorean
                              ? 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/ko/[POPLE] 이해상충 선언서 양식.docx'
                              : 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/[POPLE] Declaration of Conflict of Interest form.docx'
                          }
                        >
                          {isKorean ? '이해상충 선언서.docx' : 'Declaration of Conflict of Interest Form.docx'}
                        </Button>
                        <Button
                          variant='outlined'
                          style={{ ...styles.button }}
                          component='a'
                          target='_blank'
                          sx={{
                            'width': isKorean ? '264px' : '342px',
                            'border': '1px solid #5D38E5',
                            '&:hover': {
                              boxShadow: 'none',
                              color: '#8E74ED',
                              border: '1px solid',
                              outline: '0px solid #8E74ED',
                              background: 'transparent',
                            },
                            '&:active': {
                              border: '1px solid',
                              color: '#8E74ED',
                              outline: '0px solid #8E74ED',
                              background: 'transparent',
                            },
                          }}
                          rel='noopener noreferrer'
                          href={
                            isKorean
                              ? 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/ko/[POPLE] 비영속성 관리 원칙 및 가이드라인.docx'
                              : 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/[POPLE] POPLE NON-Permanence Management Principles and Guidelines.docx'
                          }
                        >
                          {isKorean
                            ? '비영속성 관리 원칙 및 가이드라인.pdf'
                            : 'Non-Permanence Management Principles and Guidelines.pdf'}
                        </Button>
                      </div>
                    </div>
                  </div>
                </article>
              </section>
              <section id='assessment'>
                <article className='pb-12 '>
                  <div className='mb-12'>
                    <p className='mb-8 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                      {PROGRAM_DETAILS_PAGE['FEASIBILITY']['TITLE']}
                    </p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['FEASIBILITY']['DESCRIPTION']}
                    </p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['FEASIBILITY']['SUB_DESCRIPTION']}
                    </p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['FEASIBILITY']['SUB_DESCRIPTION_1']}
                    </p>
                    <Button
                      variant='outlined'
                      style={{ ...styles.button, width: 'inherit' }}
                      component='a'
                      target='_blank'
                      rel='noopener noreferrer'
                      sx={{
                        'border': '1px solid #5D38E5',
                        '&:hover': {
                          boxShadow: 'none',
                          color: '#8E74ED',
                          border: '1px solid',
                          outline: '0px solid #8E74ED',
                          background: 'transparent',
                        },
                        '&:active': {
                          border: '1px solid',
                          color: '#8E74ED',
                          outline: '0px solid #8E74ED',
                          background: 'transparent',
                        },
                      }}
                      href={
                        isKorean
                          ? 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/ko/[POPLE] 타당성 평가 보고서 양식.docx'
                          : 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/[POPLE] Validation Report Form.docx'
                      }
                    >
                      {isKorean ? '타당성 평가 보고서.docx' : 'Validation Report Template.docx'}
                    </Button>
                  </div>
                </article>
              </section>
              <section id='verification'>
                <article className='pb-12 '>
                  <div className='mb-12'>
                    <p className='mb-8 leading-6 ' style={{ fontWeight: '500', fontSize: '20px' }}>
                      {PROGRAM_DETAILS_PAGE['VERIFICATION']['TITLE']}
                    </p>
                    <p className='mb-5 leading-7 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['VERIFICATION']['DESCRIPTION']}
                    </p>
                    <p className='mb-5 leading-7 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['VERIFICATION']['SUB_DESCRIPTION']}
                    </p>
                    <p className='mb-5 leading-7 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['VERIFICATION']['SUB_DESCRIPTION_1']}
                    </p>
                    <div className='flex justify-start '>
                      <Button
                        variant='outlined'
                        style={{ ...styles.button }}
                        component='a'
                        target='_blank'
                        rel='noopener noreferrer'
                        sx={{
                          'border': '1px solid #5D38E5',
                          '&:hover': {
                            boxShadow: 'none',
                            color: '#8E74ED',
                            border: '1px solid',
                            outline: '0px solid #8E74ED',
                            background: 'transparent',
                          },
                          '&:active': {
                            border: '1px solid',
                            color: '#8E74ED',
                            outline: '0px solid #8E74ED',
                            background: 'transparent',
                          },
                        }}
                        href={
                          isKorean
                            ? 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/ko/[POPLE] 모니터링 보고서 양식.docx'
                            : 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/[POPLE] Monitoring Report.docx'
                        }
                      >
                        {isKorean ? '모니터링 보고서.docx' : 'Monitoring Template'}
                      </Button>
                    </div>
                  </div>
                </article>
              </section>
              <section id='vrc-certification'>
                <article className='pb-12 '>
                  <div className='mb-12'>
                    <p className='mb-8 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                      {PROGRAM_DETAILS_PAGE['VRC_CERTIFICATION']['TITLE']}
                    </p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['VRC_CERTIFICATION']['SUB']}
                    </p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['VRC_CERTIFICATION']['SUB_DESCRIPTION']}
                    </p>
                  </div>
                </article>
              </section>
              <section id='vrc-issuance'>
                <article className='pb-12 '>
                  <div className='mb-12'>
                    <p className='mb-8 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                      {PROGRAM_DETAILS_PAGE['VRC_ISSUANCE']['TITLE']}
                    </p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['VRC_ISSUANCE']['DESCRIPTION']}
                    </p>
                    <p
                      className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap'
                      style={{ ...styles.paragraph }}
                      dangerouslySetInnerHTML={{ __html: PROGRAM_DETAILS_PAGE['VRC_ISSUANCE']['SUB_DESCRIPTION'] }}
                    ></p>
                    <div className='flex justify-start '>
                      <Button
                        variant='outlined'
                        style={{ ...styles.button }}
                        component='a'
                        target='_blank'
                        rel='noopener noreferrer'
                        sx={{
                          'border': '1px solid #5D38E5',
                          '&:hover': {
                            boxShadow: 'none',
                            color: '#8E74ED',
                            border: '1px solid',
                            outline: '0px solid #8E74ED',
                            background: 'transparent',
                          },
                          '&:active': {
                            border: '1px solid',
                            color: '#8E74ED',
                            outline: '0px solid #8E74ED',
                            background: 'transparent',
                          },
                        }}
                        href={
                          isKorean
                            ? 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/ko/[POPLE] POPLE 마켓플레이스 등록 양식.docx'
                            : 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_detail/[POPLE] POPLE_Marketplace Registration Form.docx'
                        }
                      >
                        {isKorean ? '마켓 플레이스 등록 양식.docx' : 'Marketplace Registration Form.docx'}
                      </Button>
                    </div>
                  </div>
                </article>
              </section>
              <section id='faq'>
                <article className='pb-12 '>
                  <div className='mb-12'>
                    <p className='mb-8 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                      FAQ
                    </p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {PROGRAM_DETAILS_PAGE['FAQ']}
                    </p>

                    <div className='flex justify-start '>
                      <Button
                        variant='outlined'
                        style={{ ...styles.button }}
                        onClick={handleGotoFaq}
                        sx={{
                          'border': '1px solid #5D38E5',
                          '&:hover': {
                            boxShadow: 'none',
                            color: '#8E74ED',
                            border: '1px solid',
                            outline: '0px solid #8E74ED',
                            background: 'transparent',
                          },
                          '&:active': {
                            border: '1px solid',
                            color: '#8E74ED',
                            outline: '0px solid #8E74ED',
                            background: 'transparent',
                          },
                        }}
                      >
                        {isKorean ? '이동하기' : 'Go'} {<ArrowForwardIcon />}
                      </Button>
                    </div>
                  </div>
                </article>
              </section>
            </section>
          </div>
        </div>
      </article>
      <article className='bg-[#5D38E5] h-[320px]'>
        <div
          className='flex justify-center w-full '
          style={{ display: 'flex', alignSelf: 'center', justifyItems: 'center' }}
        >
          <h2 style={{ fontSize: '24px', fontWeight: 500, lineHeight: '26px' }} className='flex text-white mt-[80px]'>
            {isKorean ? '프로그램에 참여하고 싶으신가요?' : 'Interested in joining the program?'}
          </h2>
        </div>
        <div className='flex justify-center'>
          <p className='mt-5 text-white' style={{ fontSize: '16px', lineHeight: '26px', textWrap: 'nowrap' }}>
            {isKorean
              ? '탄소 중립을 향한 여정에 함께 참여하시고 싶으시면 언제든지 문의해주세요.'
              : 'Contact us to be part of the journey toward carbon neutrality.'}
          </p>
        </div>
        <div className='flex justify-center mt-7'>
          <Button
            onClick={handleInquiries}
            variant='contained'
            sx={{
              'borderRadius': 0,
              'width': 298,
              'height': 56,
              'backgroundColor': '#222222',
              'fontSize': '16px',
              'textWrap': 'nowrap',
              'outline': '#222222',
              'boxShadow': 0,
              '&:hover': {
                boxShadow: 0,
                background: '#FFFFFF',
                outline: 'none',
                border: '0px solid #FFFFFF',
                color: '#222222',
              },
              '&:active': {
                boxShadow: 0,
                background: '#FFFFFF',
                color: '#222222',
                outline: 'none',
                border: '1px solid #FFFFFF',
              },
            }}
          >
            {isKorean ? '문의하기' : 'Contact Us'} {<ArrowForwardIcon fontSize='medium' />}
          </Button>
        </div>
      </article>
    </article>
  );
};