import _ from 'lodash';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Handler from '../../Handler';
import API from '../../api';
import { ContentProject, Stepper } from '../../components';
import { useForm } from '../../hooks';

export const PublicProjectPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const searchObject = queryString.parse(history.location.search);
  const [step, setStep] = useState(0);
  const [methodologyInfo, setMethodologyInfo] = useState(null);
  const { seq, version, token } = useParams();

  //project content data
  const [formData, formMeta, formChange, setFormData, setFormMeta] = useForm(
    {
      companyName: '',
      ceoName: '',
      businessNumber: '',
      businessFile: '',
      address: null,
      site: '',
      fax: '',
      name: '',
      phone: '',
      department: '',
      email: '',
      isExported: false,
      title: '',
      area: '',
      subArea: '',
      methodologySeq: '',
      purpose: '',
      content: '',
      location: { addr1: '', addr2: '' },
      isDomestic: false,
      workplaceImages: [],
      reductionBase: '',
      reductionBusiness: '',
      reductionLeak: '',
      reduction: '',
      monitoringType: '',
      monitoringPlan: '',
      beforeImages: [],
      afterImages: [],
      evidence: [],
      proofFile: '',
    },
    {
      companyName: {
        key: 'companyName',
        label: '기업명',
        disabled: true,
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      ceoName: {
        key: 'ceoName',
        label: '대표자명',
        disabled: true,
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      businessNumber: {
        key: 'businessNumber',
        label: '사업자등록번호',
        disabled: true,
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      businessFile: {
        type: 'file',
        key: 'businessFile',
        label: '사업자등록증',
        path: 'project/editor',
        disabled: true,
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      address: {
        required: true,
        key: 'address',
        label: '본사 주소',
        type: 'content',
        disabled: true,
        style: {
          gridColumn: '1/3',
        },
      },
      site: {
        key: 'site',
        disabled: true,
        label: '홈페이지',
        style: {
          maxWidth: '420px',
        },
      },
      fax: {
        key: 'fax',
        label: '팩스번호',
        disabled: true,
        style: {
          maxWidth: '420px',
        },
      },
      name: {
        key: 'name',
        label: '담당자명',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      phone: {
        key: 'phone',
        label: '연락처(휴대폰)',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      department: {
        key: 'department',
        label: '부서명',
        required: false,
        style: {
          maxWidth: '420px',
        },
      },
      email: {
        key: 'email',
        label: '담당자 이메일',
        required: true,
        style: {
          maxWidth: '420px',
        },
      },
      isExported: {
        key: 'isExported',
        style: {
          gridColumn: '1/3',
        },
        label: '타 기관 등록(신청) 여부',
        subLabel:
          '신청하시는 사업이 ‘타기관에 등록되어 있거나 등록 신청이 되어 있는지’의 여부를 표시해주시기 바랍니다.',
        required: true,
        menus: [
          {
            text: 'NO',
            label: 'NO',
            value: false,
          },
          {
            text: '있음(step3에서 타기관 등록 인증서 필수 첨부)',
            label: 'YES',
            value: true,
          },
        ],
      },
      title: {
        key: 'title',
        label: '사업명',
        required: true,
        style: {
          gridColumn: '1/3',
        },
      },
      area: {
        key: 'area',
        label: '사업 유형',
        required: true,
        style: {
          maxWidth: '420px',
        },
        menus: [
          {
            value: 'Removal',
            label: Handler.ENUM.AREA('Removal'),
          },
          {
            value: 'Reduction',
            label: Handler.ENUM.AREA('Reduction'),
          },
        ],
      },
      subArea: {
        key: 'subArea',
        label: '사업 유형 상세',
        required: true,
        style: {
          maxWidth: '420px',
        },
        menus: [],
      },
      methodologySeq: {
        key: 'methodologySeq',
        label: '적용 방법론',
        required: true,
        style: {
          gridColumn: '1/3',
        },
        value: '',
        menus: [],
      },
      purpose: {
        key: 'purpose',
        label: '사업 목적',
        maxSize: 500,
        style: {
          gridColumn: '1/3',
        },
        required: true,
      },
      content: {
        key: 'content',
        label: '사업 내용',
        path: 'dummy',
        required: true,
        style: {
          gridColumn: '1/3',
        },
        className: 'content',
      },
      location: {
        required: true,
        key: 'location',
        label: '사업 시행 장소',
        type: 'content',
        style: {
          gridColumn: '1/3',
        },
      },
      workplaceImages: {
        key: 'workplaceImages',
        type: 'image',
        label: '사업장 사진',
        subLabel: '첨부파일은 최대 10MB 가능합니다.',
        path: 'dummy',
        max: 10,
        style: {
          gridColumn: '1/3',
        },
      },
      reductionBase: {
        key: 'reductionBase',
        label: '베이스라인 배출량 (흡수량)',
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        style: {},
      },
      reductionBusiness: {
        key: 'reductionBusiness',
        label: '사업 배출량 (흡수량)',
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        style: {},
      },
      reductionLeak: {
        key: 'reductionLeak',
        label: '누출량',
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        style: {},
      },
      reduction: {
        key: 'reduction',
        label: '온실가스 예상 감축량 (순흡수량)',
        type: 'number',
        className: 'unit',
        unitLabel: `tCO2-eq / ${t('A_YEAR')}`,
        required: true,
        disabled: true,
        style: {},
      },
      monitoringType: {
        key: 'monitoringType',
        style: {
          gridColumn: '1/3',
        },
        label: '모니터링 방식 선택',
        subLabel: `모니터링 방식 구분
          - 서면 모니터링 방식 :  신청자가 모니터링 입력 시스템에 데이터를 직접 입력하는 방식입니다.
          - 시스템 모니터링 방식 : 신청 기업이 보유한 내부 시스템을 연결해 데이터를 제공 받는 방식으로,  제공 받은 데이터가 계산되어 모니터링 입력 시스템에서 자동으로 출력 됩니다.`,
        required: true,
        menus: [
          {
            label: '서면 모니터링',
            value: 'Document',
          },
          {
            label: '시스템 모니터링',
            value: 'System',
          },
        ],
      },
      monitoringPlan: {
        key: 'monitoringPlan',
        label: '모니터링 계획 설명',
        path: 'project/editor',
        required: true,
        type: 'content',
        style: {
          gridColumn: '1/3',
        },
      },
      beforeImages: {
        key: 'beforeImages',
        type: 'image',
        label: '사업 전 사진',
        subLabel: '첨부파일은 최대 10MB 가능합니다.',
        path: 'dummy',
        max: 10,
        style: {
          gridColumn: '1/3',
        },
      },
      afterImages: {
        key: 'afterImages',
        type: 'image',
        label: '사업 후 사진',
        subLabel: '첨부파일은 최대 10MB 가능합니다.',
        path: 'dummy',
        max: 10,
        style: {
          gridColumn: '1/3',
        },
      },
      evidence: {
        key: 'evidence',
        label: [],
      },
      proofFile: {
        type: 'file',
        key: 'proofFile',
        label: '사업 인증서',
        path: 'project/editor',
        required: true,
        style: {
          gridColumn: '1/3',
        },
      },
    },
  );
  const [contentMeta, setContentMeta] = useState({
    isSameAddress: false,
    methodologyYear: 0,
    termsAggree: false,
    termsList: [],
    methodologyTitle: '',
  });
  const [lastDocument, setLastDocument] = useState(null);

  useEffect(() => {
    console.log('searchObject', searchObject);
    // let seq = searchObject.seq;
    // let version = searchObject.version;
    // let token = searchObject.token;

    console.log('seq', seq);
    console.log('version', version);
    console.log('token', token);

    if (seq && version && token) {
      API.Public.GetProject(seq, { version: version }, token).then(
        (res) => {
          let result = res.data;
          console.log('result', result);
          setLastDocument(result);
        },
        (err) => {
          alert(err.data.message);
        },
      );
    } else {
      // window.close();
    }

    API.Common.GetMethodology().then(
      (res) => {
        let result = res.data;
       
        setMethodologyInfo(result);
      },
      (err) => {
        console.log('err', err);
      },
    );
  }, []);

  useEffect(() => {
    document.body.scrollTop = 0;
  }, [step]);

  useEffect(() => {
    console.log('lastDocument', lastDocument);
    if (lastDocument && methodologyInfo) {
      let init_termsAgree = false;
      let init_methodologyTitle = '';
      if (lastDocument.termsMapper.length > 0) {
        init_termsAgree = true;
      }

      let originMethodology = null;
      if (lastDocument.project && lastDocument.project.methodology) {
        originMethodology = Handler.getMethodologyBySeq(methodologyInfo, lastDocument.project.methodology.seq);
        //증빙서류 업데이트
        console.log('originMethodology', originMethodology);
        let evidence_label = Handler.buildEvidenceLabel(originMethodology, false);
        console.log('evidence_label', evidence_label);
        let new_formMeta = _.clone(formMeta);
        new_formMeta.evidence.label = evidence_label;
        setFormMeta(new_formMeta);
        //methodology title meta update
        init_methodologyTitle = lastDocument.project.methodology.title;
      }

      // evidence check;
      let originEvidence = null;
      if (lastDocument.evidenceMapper) {
        originEvidence = Handler.getEvidenceByMapper(lastDocument.evidenceMapper, false);
      }
      console.log('originEvidence', originEvidence);

      //update logic
      let new_contentMeta = _.clone(contentMeta);
      new_contentMeta.termsAggree = init_termsAgree;
      new_contentMeta.methodologyTitle = init_methodologyTitle;

      //setup terms
      let terms_value = Handler.buildTermsValue(originMethodology);
      console.log('terms_value', terms_value);
      new_contentMeta['termsList'] = terms_value;
      new_contentMeta['methodologyYear'] = originMethodology.expiredYear;
      new_contentMeta['methodologyTitle'] = originMethodology.title;
      new_contentMeta['calculateFile'] = originMethodology.calculateFile;
      new_contentMeta['isSameAddress'] = lastDocument.isCheckedArea;

      const getInitLocation = (data) => {
        if (data.location) {
          return data.location;
        } else {
          return null;
        }
      };

      setContentMeta(new_contentMeta);
      // setHoldUpdateData(true);
      setFormData({
        ...formData,
        address: lastDocument.project.user.address,
        companyName: lastDocument.project.user.companyName,
        ceoName: lastDocument.project.user.ceoName,
        businessNumber: lastDocument.project.user.businessNumber,
        businessFile: lastDocument.project.user.businessFile,
        site: lastDocument.project.user.site,
        fax: lastDocument.project.user.fax,
        name: lastDocument.name,
        phone: lastDocument.phone,
        department: lastDocument.department,
        email: lastDocument.email,
        isExported: lastDocument.isExported,
        title: lastDocument.title,
        area: originMethodology ? originMethodology.area : '',
        subArea: originMethodology ? originMethodology.subArea : '',
        methodologySeq: originMethodology ? originMethodology.seq : '',
        purpose: lastDocument.purpose,
        content: lastDocument.content,
        isDomestic: lastDocument.isDomestic,
        location: getInitLocation(lastDocument),
        reductionBase: lastDocument.reductionBase,
        reductionBusiness: lastDocument.reductionBusiness,
        reductionLeak: lastDocument.reductionLeak,
        reduction: lastDocument.reduction,
        monitoringType: lastDocument.monitoringType,
        monitoringPlan: lastDocument.monitoringPlan,
        beforeImages: lastDocument.beforeImages ? lastDocument.beforeImages : [],
        afterImages: lastDocument.afterImages ? lastDocument.afterImages : [],
        evidence: originEvidence,
        workplaceImages: lastDocument.workplaceImages ? lastDocument.workplaceImages : [],
        exportedTitle: lastDocument.exportedTitle,
        exportedOrgan: lastDocument.exportedOrgan,
        exportedDate: lastDocument.exportedDate,
      });
    }
  }, [lastDocument, methodologyInfo]);

  const onCahngeStep = (new_value) => {
    setStep(new_value);
  };

  return (
    <article className='public-page'>
      <article className='public-button__container'>
        <section className='step-left-button__box'>
          <div
            onClick={() => {
              if (step > 0) {
                setStep(step - 1);
              }
            }}
          >
            <div className='ico prev-big'></div>
          </div>
        </section>
        <section className='step-right-button__box'>
          <div
            onClick={() => {
              if (step < 2) {
                setStep(step + 1);
              }
            }}
          >
            <div className='ico next-big'></div>
          </div>
        </section>
      </article>
      <article className='content__wrapper'>
        <Stepper
          meta={{ type: 'round', style: { marginTop: 40 } }}
          value={step}
          label={['사업 개요', '온실가스 예상 감축량 & 기타 증빙 자료', '최종 확인']}
          onChange={onCahngeStep}
        ></Stepper>
        <ContentProject
          step={step}
          formData={formData}
          formMeta={formMeta}
          formChange={formChange}
          setFormData={setFormData}
          setFormMeta={setFormMeta}
          contentMeta={contentMeta}
          contentMetaChange={() => {}}
          readOnly={true}
          type={''}
        ></ContentProject>
      </article>
    </article>
  );
};
