import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { PageHeader } from '../../components';
import { LangContext } from '../../contexts';
import { useLabel } from '../../hooks';
import Process from '../../styles/images/ARROWS.svg';
import ProcessEn from '../../styles/images/ARROWS_En.svg';
import RiskAssess from '../../styles/images/RiskAssess.svg';
import RiskAssessEn from '../../styles/images/RiskAssessEn.svg';

const styles = {
  paragraph: {
    fontSize: '16px',
    color: '#606369',
    fontWeight: 300,
  },
  sideBar: {
    position: 'sticky',
    display: 'block',
    top: '200px',
    margin: 20,
  },
  button: {
    borderRadius: '0',
    height: 44,
    marginRight: '10px',
    fontSize: '15px',
    lineHeight: '20px',
    padding: '10px 20px',
    width: 'inherit',
    textWrap: 'nowrap',
  },
  activeLink: {
    color: '#5D38E5',
    fontWeight: 600,
  },
  inactiveLink: {
    color: '#999EAD',
    fontWeight: 400,
  },
};

export const ProgramPage = (props) => {
  const { isKorean } = useContext(LangContext);
  const { STANDARD_DOCUMENT_PAGE } = useLabel();
  const { t } = useTranslation();
  const [activeLink, setActiveLink] = useState('process');
  const history = useHistory();
  const location = useLocation();

  const handleNavClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const headerOffset = 200;
      const sectionPosition = section.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = sectionPosition - headerOffset;
      setActiveLink(sectionId);
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const inactiveLinkStyle = {
    color: '#999EAD',
    fontWeight: 400,
  };
  const activeLinkStyle = {
    color: '#5D38E5',
    fontWeight: 600,
  };

  useEffect(() => {
    setActiveLink('process');
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.target.id) {
            setActiveLink(entry.target.id);
          }
        });
      },
      { threshold: 0.4, rootMargin: '0px 0px -55% 0px' },
    );

    const sections = document.querySelectorAll('section[data-anchor]');
    sections.forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    console.log('Location search ------->', location.hash);

    if (location.hash) {
      const scrollToSafeguard = location.hash.split('#')[1];
      console.log(scrollToSafeguard);
      handleNavClick(scrollToSafeguard);
      const targetElement = document.getElementById(scrollToSafeguard);
      console.log('TARGET ELEMENT -------->', targetElement);
      if (targetElement.id === 'fee') {
        console.log('ID:', targetElement.id);
        window.scrollTo({
          top: targetElement.offsetTop + 730,
          behavior: 'smooth',
        });
        handleNavClick(targetElement);
      } else {
        window.scrollTo({
          top: targetElement.offsetTop - 145,
          behavior: 'smooth',
        });
      }
    }
  }, [location]);

  const goToDetailsPage = () => {
    history.push('/program/program/detail');
  };
  return (
    <article className='h-full content'>
      <article className='basic-page'>
        <article className='basic-page bg-[#FAF9FE] h-full pb-6'>
          <PageHeader type='standard'>
            <div className='flex justify-between w-full'>
              <div>
                <h1 style={{ fontWeight: 600 }} className='text-[36px]'>{`${STANDARD_DOCUMENT_PAGE['TITLE']}`}</h1>
              </div>
              <div className='leading-7 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                <p className='leading-7 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                  {STANDARD_DOCUMENT_PAGE['DESCRIPTION']}
                </p>
                <p className='mt-5 leading-7 whitespace-pre-wrap description'>
                  {STANDARD_DOCUMENT_PAGE['DESCRIPTION_BOTTOM']}
                </p>
                <div className='mt-10'>
                  <Button
                    variant='contained'
                    style={{
                      borderRadius: '0',
                      height: 56,
                      fontSize: '16px',
                      textWrap: 'nowrap',
                      padding: '16px 24px',
                      width: isKorean ? '258px' : '196px',
                    }}
                    onClick={goToDetailsPage}
                    sx={{
                      'outline': '#5D38E5',
                      'border': '#5D38E5',
                      'boxShadow': 0,
                      '&:hover': {
                        backgroundColor: '  #8E74ED',
                        color: '#FFFFFF',
                        border: '1px solid #8E74ED',
                        outline: '0px solid #8E74ED',
                        boxShadow: 0,
                      },
                      '&:actove': {
                        backgroundColor: '  #5D38E5',
                        color: '#FFFFFF',
                        border: '1px solid #5D38E5',
                        outline: '0px solid #5D38E5',
                        boxShadow: 0,
                      },
                    }}
                  >
                    {isKorean ? '프로그램 상세 정보 바로가기' : 'Program Details'}
                    {<ArrowForwardIcon fontSize='medium' />}
                  </Button>
                </div>
              </div>
            </div>
          </PageHeader>
        </article>
        <div className='row-page-row py-[80px]' style={{ display: 'flex', top: '200px' }}>
          <div className='fixed-sidebar'>
            <nav className='flex fixed-sidebar' style={styles.sideBar}>
              <div
                style={{
                  borderRight: '1px solid #D7DAE2',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  paddingRight: 24,
                  width: 200,
                  textWrap: 'nowrap',
                }}
              >
                <Button
                  onClick={() => handleNavClick('process')}
                  style={activeLink === 'process' ? styles.activeLink : inactiveLinkStyle}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '&:active': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  {STANDARD_DOCUMENT_PAGE['PROCESS_TITLE']['TITLE']}
                </Button>
                <Button
                  onClick={() => handleNavClick('safeguard')}
                  style={activeLink === 'safeguard' ? styles.activeLink : inactiveLinkStyle}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '&:active': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  {STANDARD_DOCUMENT_PAGE['SAFEGUARD']['TITLE']}
                </Button>
                <Button
                  onClick={() => handleNavClick('conflict')}
                  style={activeLink === 'conflict' ? styles.activeLink : inactiveLinkStyle}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '&:active': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  {STANDARD_DOCUMENT_PAGE['CONFLICT-OF-INTEREST']['TITLE']}
                </Button>
                <Button
                  onClick={() => handleNavClick('risk')}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '&:active': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  style={activeLink === 'risk' ? styles.activeLink : inactiveLinkStyle}
                >
                  {STANDARD_DOCUMENT_PAGE['RISK-ASSESSMENT']['TITLE']}
                </Button>
                <Button
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '&:active': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  onClick={() => handleNavClick('fee')}
                  style={activeLink === 'fee' ? styles.activeLink : inactiveLinkStyle}
                >
                  {STANDARD_DOCUMENT_PAGE['FEE']['TITLE']}
                </Button>
                <Button
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '&:active': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  onClick={() => handleNavClick('billing')}
                  style={activeLink === 'billing' ? styles.activeLink : inactiveLinkStyle}
                >
                  {isKorean ? '청구 가이드' : 'Claims'}
                </Button>
                <Button
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '&:active': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  onClick={() => handleNavClick('problem-solving')}
                  style={activeLink === 'problem-solving' ? styles.activeLink : inactiveLinkStyle}
                >
                  {STANDARD_DOCUMENT_PAGE['PROBLEM-SOLVING']['TITLE']}
                </Button>
                <Button
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '&:active': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  onClick={() => handleNavClick('qa-management')}
                  style={activeLink === 'qa-management' ? styles.activeLink : inactiveLinkStyle}
                >
                  {STANDARD_DOCUMENT_PAGE['QA_MANAGEMENT']['TITLE']}
                </Button>
              </div>
            </nav>
          </div>

          <div className='content-section w-full ml-[100px] '>
            <section className='w-full py-[30px]'>
              <section id='process' data-anchor='process'>
                <article className='pb-12 '>
                  <div className='mb-7'>
                    <p className='mb-6 leading-6' style={{ fontWeight: '500', fontSize: '20px' }}>
                      {STANDARD_DOCUMENT_PAGE['PROCESS_TITLE']['TITLE']}
                    </p>
                    <p className='mb-10 leading-6 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['GOVERNANCE']['DESCRIPTION']}
                    </p>
                    {isKorean ? (
                      <img src={Process} alt='프로세스' className='mb-8' />
                    ) : (
                      <img src={ProcessEn} alt='Process Arrows' className='mb-8' />
                    )}

                    <div className='mb-10 ml-1 leading-7 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      <p
                        className='leading-7'
                        style={{ ...styles.paragraph }}
                        dangerouslySetInnerHTML={{ __html: STANDARD_DOCUMENT_PAGE['PROCESS']['DESCRIPTION'][0] }}
                      ></p>
                      <p
                        className='leading-7'
                        style={{ ...styles.paragraph }}
                        dangerouslySetInnerHTML={{ __html: STANDARD_DOCUMENT_PAGE['PROCESS']['DESCRIPTION'][1] }}
                      ></p>
                      <p
                        className='leading-7 '
                        style={{ ...styles.paragraph }}
                        dangerouslySetInnerHTML={{ __html: STANDARD_DOCUMENT_PAGE['PROCESS']['DESCRIPTION'][2] }}
                      ></p>
                      <p
                        className='leading-7'
                        style={{ ...styles.paragraph }}
                        dangerouslySetInnerHTML={{ __html: STANDARD_DOCUMENT_PAGE['PROCESS']['DESCRIPTION'][3] }}
                      ></p>
                      <p
                        className='leading-7 '
                        style={{ ...styles.paragraph }}
                        dangerouslySetInnerHTML={{ __html: STANDARD_DOCUMENT_PAGE['PROCESS']['DESCRIPTION'][4] }}
                      ></p>
                      <p
                        className='leading-7 '
                        style={{ ...styles.paragraph }}
                        dangerouslySetInnerHTML={{ __html: STANDARD_DOCUMENT_PAGE['PROCESS']['DESCRIPTION'][5] }}
                      ></p>
                    </div>
                  </div>
                </article>
              </section>
              <section id='safeguard' data-anchor='safeguard'>
                <article className='pb-12 '>
                  <div className='mb-12'>
                    <p className='leading-6 mb-7' style={{ fontWeight: '500', fontSize: '20px' }}>
                      {STANDARD_DOCUMENT_PAGE['SAFEGUARD']['TITLE']}
                    </p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['SAFEGUARD']['DESCRIPTION']}
                    </p>
                    <div className='flex justify-start '>
                      <Button
                        variant='outlined'
                        component='a'
                        target='_blank'
                        rel='noopener noreferrer'
                        sx={{
                          'border': '1px solid #5D38E5',
                          '&:hover': {
                            boxShadow: 'none',
                            color: '#8E74ED',
                            border: '1px solid',
                            outline: '0px solid #8E74ED',
                            background: 'transparent',
                          },
                          '&:active': {
                            border: '1px solid',
                            color: '#8E74ED',
                            outline: '0px solid #8E74ED',
                            background: 'transparent',
                          },
                        }}
                        href={
                          isKorean
                            ? 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_main/ko/[POPLE] POPLE 세이프가드 원칙 및 가이드라인.docx'
                            : 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_main/en/[POPLE] Safeguarding Principles and Guidelines.docx'
                        }
                        style={{ ...styles.button, width: 'inherit', textWrap: 'nowrap' }}
                      >
                        {isKorean ? ' 세이프가드 원칙 및 가이드라인.pdf' : 'Safeguarding Principles and Guidelines.docx'}
                      </Button>
                    </div>
                  </div>
                </article>
              </section>
              <section id='conflict' data-anchor='conflict'>
                <article className='pb-12 '>
                  <div className='mb-12'>
                    <p className='leading-6 mb-7' style={{ fontWeight: '500', fontSize: '20px' }}>
                      {STANDARD_DOCUMENT_PAGE['CONFLICT-OF-INTEREST']['TITLE']}
                    </p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['CONFLICT-OF-INTEREST']['DESCRIPTION']}
                    </p>
                    <div className='flex justify-start '>
                      <Button
                        variant='outlined'
                        style={{ ...styles.button }}
                        component='a'
                        target='_blank'
                        rel='noopener noreferrer'
                        sx={{
                          'border': '1px solid #5D38E5',
                          '&:hover': {
                            boxShadow: 'none',
                            color: '#8E74ED',
                            border: '1px solid',
                            outline: '0px solid #8E74ED',
                            background: 'transparent',
                          },
                          '&:active': {
                            border: '1px solid',
                            color: '#8E74ED',
                            outline: '0px solid #8E74ED',
                            background: 'transparent',
                          },
                        }}
                        href={
                          isKorean
                            ? 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_main/ko/[POPLE] POPLE 이해상충 정책.docx'
                            : 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_main/en/[POPLE] POPLE Conflict of Interest Policy.pdf'
                        }
                      >
                        {isKorean ? '이해 상충 관리 정책.pdf' : 'Conflict of Interest Policy.pdf'}
                      </Button>
                      <Button
                        component='a'
                        target='_blank'
                        rel='noopener noreferrer'
                        href={
                          isKorean
                            ? 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_main/ko/[POPLE] 이해상충 선언서 양식.docx'
                            : 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_main/en/[POPLE] Declaration of Conflict of Interest form.docx'
                        }
                        variant='outlined'
                        style={{ ...styles.button, width: 'inherit', textWrap: 'nowrap' }}
                        sx={{
                          'border': '1px solid #5D38E5',
                          '&:hover': {
                            boxShadow: 'none',
                            color: '#8E74ED',
                            border: '1px solid',
                            outline: '0px solid #8E74ED',
                            background: 'transparent',
                          },
                          '&:active': {
                            border: '1px solid',
                            color: '#8E74ED',
                            outline: '0px solid #8E74ED',
                            background: 'transparent',
                          },
                        }}
                      >
                        {isKorean ? '이해상충 선언서.docx' : 'Declaration of Conflict of Interest Form.docx'}
                      </Button>
                    </div>
                  </div>
                </article>
              </section>
              <section id='risk' data-anchor='risk'>
                <article className='pb-12 '>
                  <div className='mb-7'>
                    <p className='leading-6 mb-7' style={{ fontWeight: '500', fontSize: '20px' }}>
                      {STANDARD_DOCUMENT_PAGE['RISK-ASSESSMENT']['TITLE']}
                    </p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['RISK-ASSESSMENT']['DESCRIPTION']}
                    </p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['RISK-ASSESSMENT']['SUB_DESCRIPTION']}
                    </p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['RISK-ASSESSMENT']['SUB_DESCRIPTION_1']}
                    </p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['RISK-ASSESSMENT']['SUB_DESCRIPTION_2']}
                    </p>
                    <div className='flex justify-start '>
                      <Button
                        variant='outlined'
                        style={{ ...styles.button }}
                        component='a'
                        target='_blank'
                        rel='noopener noreferrer'
                        sx={{
                          'border': '1px solid #5D38E5',
                          '&:hover': {
                            boxShadow: 'none',
                            color: '#8E74ED',
                            border: '1px solid',
                            outline: '0px solid #8E74ED',
                            background: 'transparent',
                          },
                          '&:active': {
                            border: '1px solid',
                            color: '#8E74ED',
                            outline: '0px solid #8E74ED',
                            background: 'transparent',
                          },
                        }}
                        href={
                          isKorean
                            ? 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_main/ko/[POPLE] POPLE 비영속성 관리 원칙 및 가이드라인.docx'
                            : 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_main/en/[POPLE] POPLE NON-Permanence Management Principles and Guidelines.docx'
                        }
                      >
                        {isKorean
                          ? '비영속성 관리 원칙 및 가이드라인.pdf'
                          : 'Non-Permanence and Risk Management Principles and Guidelines.docx'}
                      </Button>
                    </div>
                    <div className='mt-7'>
                      {isKorean ? (
                        <img src={RiskAssess} alt='리스크 관리 평가' />
                      ) : (
                        <img src={RiskAssessEn} alt='Risk Assessment' />
                      )}
                    </div>
                  </div>
                </article>
              </section>
              <section id='fee' data-anchor='fee'>
                <article className='pb-12 '>
                  <div className='mb-12'>
                    <p className='leading-6 mb-7' style={{ fontWeight: '500', fontSize: '20px' }}>
                      {STANDARD_DOCUMENT_PAGE['FEE']['TITLE']}
                    </p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['FEE']['DESCRIPTION']}
                    </p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['FEE']['SUB_DESCRIPTION']}
                    </p>
                    <div className='flex justify-start '>
                      <Button
                        variant='outlined'
                        style={{ ...styles.button, width: 'inherit', textWrap: 'nowrap' }}
                        component='a'
                        target='_blank'
                        rel='noopener noreferrer'
                        sx={{
                          'border': '1px solid #5D38E5',
                          '&:hover': {
                            boxShadow: 'none',
                            color: '#8E74ED',
                            border: '1px solid',
                            outline: '0px solid #8E74ED',
                            background: 'transparent',
                          },
                          '&:active': {
                            border: '1px solid',
                            color: '#8E74ED',
                            outline: '0px solid #8E74ED',
                            background: 'transparent',
                          },
                        }}
                        href={
                          isKorean
                            ? 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_main/ko/[POPLE] 프로그램 수수료 체계.docx'
                            : 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_main/en/%5bPOPLE%5d%20POPLE%20Fee%20Schedule.pdf'
                        }
                      >
                        {isKorean ? '프로그램 수수료 체계.docx' : 'Fee Schedule.pdf'}
                      </Button>
                    </div>
                  </div>
                </article>
              </section>
              <section id='billing' data-anchor='billing'>
                <article className='pb-12 '>
                  <div className='mb-12'>
                    <p
                      className='leading-6 mb-7 '
                      style={{ fontWeight: '500', fontSize: '20px', width: 'inherit', textWrap: 'nowrap' }}
                    >
                      {isKorean ? '청구 가이드' : 'Claims'}
                    </p>
                    <p className='mb-5 leading-7 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['BILLING-GUIDELINE'][0]}
                    </p>
                    <p className='mb-5 leading-7 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['BILLING-GUIDELINE'][1]}
                      {STANDARD_DOCUMENT_PAGE['BILLING-GUIDELINE'][2]}
                      {STANDARD_DOCUMENT_PAGE['BILLING-GUIDELINE'][3]}
                    </p>
                    <div className='flex justify-start '>
                      <Button
                        variant='outlined'
                        style={{ ...styles.button }}
                        component='a'
                        target='_blank'
                        rel='noopener noreferrer'
                        sx={{
                          'border': '1px solid #5D38E5',
                          '&:hover': {
                            boxShadow: 'none',
                            color: '#8E74ED',
                            border: '1px solid',
                            outline: '0px solid #8E74ED',
                            background: 'transparent',
                          },
                          '&:active': {
                            border: '1px solid',
                            color: '#8E74ED',
                            outline: '0px solid #8E74ED',
                            background: 'transparent',
                          },
                        }}
                        href={
                          isKorean
                            ? 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_main/ko/[POPLE] 청구 정책.docx'
                            : 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_main/en/[POPLE] POPLE Claims Policy.pdf'
                        }
                      >
                        {isKorean ? '청구 정책.pdf' : 'Claims Policy.pdf'}
                      </Button>
                    </div>
                  </div>
                </article>
              </section>
              <section id='problem-solving' data-anchor='problem-solving'>
                <article className='pb-12 '>
                  <div className='mb-12'>
                    <p className='leading-6 mb-7' style={{ fontWeight: '500', fontSize: '20px' }}>
                      {STANDARD_DOCUMENT_PAGE['PROBLEM-SOLVING']['TITLE']}
                    </p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['PROBLEM-SOLVING']['DESCRIPTION']}
                    </p>
                    <div className='flex justify-start '>
                      <Button
                        variant='outlined'
                        style={{ ...styles.button }}
                        component='a'
                        target='_blank'
                        rel='noopener noreferrer'
                        sx={{
                          'border': '1px solid #5D38E5',
                          '&:hover': {
                            boxShadow: 'none',
                            color: '#8E74ED',
                            border: '1px solid',
                            outline: '0px solid #8E74ED',
                            background: 'transparent',
                          },
                          '&:active': {
                            border: '1px solid',
                            color: '#8E74ED',
                            outline: '0px solid #8E74ED',
                            background: 'transparent',
                          },
                        }}
                        href={
                          isKorean
                            ? 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_main/ko/[POPLE] POPLE 불만 처리 정책.docx'
                            : 'https://d1sjg004kl9yle.cloudfront.net/prod/programs/icroa/program_main/en/[POPLE] POPLE Grievance Resolution Policy.pdf'
                        }
                      >
                        {isKorean ? '불만 처리 정책.docx' : 'Grievance Resolution Policy.pdf'}
                      </Button>
                    </div>
                  </div>
                </article>
              </section>
              <section id='qa-management' data-anchor='qa-management'>
                <article className='pb-12 '>
                  <div className='mb-12'>
                    <p className='leading-6 mb-7' style={{ fontWeight: '500', fontSize: '20px' }}>
                      {STANDARD_DOCUMENT_PAGE['QA_MANAGEMENT']['TITLE']}
                    </p>
                    <p
                      className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap'
                      style={{ ...styles.paragraph }}
                      dangerouslySetInnerHTML={{ __html: STANDARD_DOCUMENT_PAGE['QA_MANAGEMENT']['DESCRIPTION'] }}
                    ></p>
                    <p
                      className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap'
                      style={{ ...styles.paragraph }}
                      dangerouslySetInnerHTML={{ __html: STANDARD_DOCUMENT_PAGE['QA_MANAGEMENT']['SUB_DESCRIPTION_1'] }}
                    ></p>

                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}></p>
                    <p className='mb-5 leading-7 text-gray-700 whitespace-pre-wrap' style={{ ...styles.paragraph }}>
                      {STANDARD_DOCUMENT_PAGE['QA_MANAGEMENT']['SUB_DESCRIPTION_2']}
                      {STANDARD_DOCUMENT_PAGE['QA_MANAGEMENT']['SUB_DESCRIPTION_3']}
                    </p>
                  </div>
                </article>
              </section>
            </section>
          </div>
        </div>
      </article>
      <article className='bg-[#5D38E5] h-[320px]'>
        <div
          className='flex justify-center w-full '
          style={{ display: 'flex', alignSelf: 'center', justifyItems: 'center' }}
        >
          <h2 style={{ fontSize: '24px', fontWeight: 500, lineHeight: '26px' }} className='flex text-white mt-[80px]'>
            {isKorean ? '프로그램에 대한 전반적인 정책을 이해하셨나요?' : 'Ready to learn more about our program?'}
          </h2>
        </div>
        <div className='flex justify-center'>
          <p className='mt-5 text-white' style={{ fontSize: '16px', lineHeight: '26px', fontWeight: 200 }}>
            {isKorean
              ? '프로그램의 구체적인 방법과 절차에 대한 상세 정보를 원하신다면 아래 버튼을 클릭해 주세요.'
              : 'Click below for detailed guidance on our methodologies and procedures.'}
          </p>
        </div>
        <div className='flex justify-center mt-7'>
          <Button
            onClick={goToDetailsPage}
            variant='contained'
            sx={{
              'borderRadius': 0,
              'padding': '16px 24px',
              'width': 298,
              'height': 56,
              'backgroundColor': '#222222',
              'border': '0px solid #222222',
              'fontSize': '16px',
              'outline': 'none',
              'boxShadow': 0,
              '&:hover': {
                boxShadow: 0,
                background: '#FFFFFF',
                color: '#222222',
                outline: 'none',
                border: '0px solid #FFFFFF',
              },
              '&:active': {
                boxShadow: 0,
                background: '#FFFFFF',
                color: '#222222',
                outline: 'none',
                border: '1px solid #FFFFFF',
              },
            }}
          >
            {isKorean ? '프로그램 상세 정보 바로가기' : 'Program Details'} {<ArrowForwardIcon fontSize='medium' />}
          </Button>
        </div>
      </article>
    </article>
  );
};
