import axios from 'axios';
import AxiosInstance from '../AxiosInstance';
import { BASE_URL } from '../Config';
import Utils from '../Utils';

const PATH = 'registry/';

const FileAxiosInstance = axios.create({
  baseURL: BASE_URL,
  responseType: 'blob',
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },
});

const GetRegistryList = (category, params) => {
  const url = BASE_URL + `${PATH}${category}` + Utils.ObjectToParams(params);
  return AxiosInstance.get(url);
};

const GetRegistryExcel = (params) => {
  const url = BASE_URL + `${PATH}credit/export` + Utils.ObjectToParams(params);
  return FileAxiosInstance.get(url);
};
const GetRegistryProjectExcel = (params) => {
  const url = BASE_URL + `${PATH}project/export` + Utils.ObjectToParams(params);
  return FileAxiosInstance.get(url);
};

const GetRegistryPdf = (seq, params) => {
  const url = BASE_URL + `${PATH}credit/${seq}/export` + Utils.ObjectToParams(params);
  return FileAxiosInstance.get(url);
};

const GetRegistryDetail = (category, slug) => {
  const url = BASE_URL + `${PATH}${category}/${slug}`;
  return AxiosInstance.get(url);
};

const Registry = {
  GetRegistryList: GetRegistryList,
  GetRegistryDetail: GetRegistryDetail,
  GetRegistryExcel: GetRegistryExcel,
  GetRegistryPdf: GetRegistryPdf,
  GetRegistryProjectExcel: GetRegistryProjectExcel
};

export default Registry;
