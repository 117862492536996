import InfoIcon from '@mui/icons-material/Info';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Handler from '../../Handler';
import { LangContext } from '../../contexts';
import { useLabel } from '../../hooks';
import { NoDataRow } from './NoDataRow';

export const TableProjectRegistry = (props) => {
  const { data, rowClick } = props;
  const { t } = useTranslation();
  const { isKorean } = useContext(LangContext);
  const { AREA_TYPE, TOOLTIPS, PROJECT_STATUS, TABLE, ERROR, COUNTRY } = useLabel();
console.log("DATA------->",data);
  return (
    <article className='table-project'>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center' style={{ width: 280, minWidth: 280, maxWidth: 280 }}>
                <p className='py-1'>{TABLE['PROJECT_NAME']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 100 }}>
                <p className='py1'>{TABLE['AREA']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 150 }}>
                <p className='py-1'>{TABLE['SUBAREA']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 120 }}>
                <p className='py-1'>{TABLE['ELIGIBLE_PERIOD']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 50 }}>
                <span className='font-bold relative text-sm'>
                  {TABLE['ESTIMATED_REDUCTION']}
                  <Tooltip className='cursor-pointer' title={TOOLTIPS['ESTIMATED_REDUCTION']} placement='top' arrow>
                    <InfoIcon
                      className={`absolute -top-2 text-gray-400 ${isKorean ? '-right-4' : '-right-2'}`}
                      fontSize='10px'
                    />
                  </Tooltip>
                </span>
              </TableCell>
              <TableCell align='center' style={{ width: 180, minWidth: 180, maxWidth: 180 }}>
                <p className='py-1'>{TABLE['APPLIED_METHODOLOGY']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 80 }}>
                <p className='py-1'>{TABLE['PROJECT_STATUS']}</p>
              </TableCell>
              <TableCell align='center' style={{ width: 80 }}>
                <p className='py-1'>{TABLE['COUNTRY']}</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, idx) => (
                <TableRow
                  key={`project-row-${idx}`}
                  onClick={() => {
                    rowClick(row);
                  }}
                >
                  <TableCell align='left' style={{ width: 340 }}>
                    <span className='text-xs text-gray-400 mb-1'>{row.id}</span>
                    <Tooltip title={isKorean ? row?.title : row?.titleEn || ''} placement='top-start' arrow>
                      <p>{Handler.textEllipsis(isKorean ? row?.title || '-' : row?.titleEn || '-', 100)}</p>
                    </Tooltip>
                  </TableCell>
                  <TableCell align='center' style={{ width: 120 }}>
                    {AREA_TYPE[row?.methodology?.area.toUpperCase()]}
                  </TableCell>
                  <TableCell align='center' style={{ width: 150 }}>
                    {AREA_TYPE[row?.methodology?.subArea.toUpperCase()]}
                  </TableCell>
                  <TableCell align='center'>
                    {row.startDate && row.endDate
                      ? Handler.geni18nDatePeriod(isKorean, row.startDate, row.endDate)
                      : '-'}
                  </TableCell>
                  <TableCell align='center'>
                    <p className='mr-1'>{`${Handler.numberWithCommas(row.reductionExpect)}`}</p>
                    <span className='text-xs text-gray-500'>tCO₂eq</span>
                  </TableCell>
                  <TableCell align='left' style={{ width: 180, minWidth: 180, maxWidth: 180 }}>
                    <Tooltip
                      title={isKorean ? row.methodology.title : row.methodology.titleEn || ''}
                      placement='top'
                      arrow
                    >
                      <p>{Handler.textEllipsis(isKorean ? row.methodology.title : row.methodology.titleEn, 30)}</p>
                    </Tooltip>
                  </TableCell>
                  <TableCell align='center'>{PROJECT_STATUS[row.type]}</TableCell>
                  <TableCell align='center'>
                    {(() => {
                      const location = Handler.jsonParser(row.lastProjectDoc?.location);
                      const country = Handler.findCountry(location?.addr1?.address_components);
                      console.log("====> contry: ", country)
                      return country 
                        ? (COUNTRY[country.short_name] 
                            ? `${COUNTRY[country.short_name]}(${country.short_name})`
                            : `${country.long_name}(${country.short_name})`)
                        : '-';
                    })()}
                  </TableCell>
                </TableRow>
              ))}
            {data && data.length === 0 && <NoDataRow content={ERROR['SEARCH_DATA_FOUND']} colSpan={7} />}
          </TableBody>
        </Table>
      </TableContainer>
    </article>
  );
};
